$primary-color: #007bff;
$dropdown-background: white;
$dropdown-border-color: #ddd;
$dropdown-hover-background: #f1f1f1;
$button-padding: 10px;
$dropdown-padding: 10px;
$dropdown-margin-top: 5px;

.dropdown-content2 {
  position: fixed;
  // top: 120px;
  background-color: $dropdown-background;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 999999999;
  margin-top: $dropdown-margin-top;
  padding: $dropdown-padding;
  border: 1px solid $dropdown-border-color;
  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      padding: 8px 12px;
      cursor: pointer;
      font-weight: 400;
      font-size: 0.8rem;
      font-family: "Roboto";

      &:hover {
        background-color: $dropdown-hover-background;
      }
    }
  }
}

.HeadingAndSearchSearchCustomerAccount {
  display: flex;
  justify-content: space-between;
  padding: 5px 0px;
  width: 100%;
  h1 {
    color: #26405e;
    font-weight: 600;
    font-family: "Roboto";
    line-height: 22px;
    text-align: center;
    font-size: 20px;
    margin-top: 5px;
  }
}
.BodyData {
  overflow-y: scroll;
  width: 100%;
  height: calc(100% - 30px);
}
.underLineTextSearch_search {
  width: fit-content;
  color: blue;
  font-size: 0.8rem;
  font-family: "Roboto";
  margin-left: 0.5rem;
  cursor: pointer;
  border-bottom: 1px solid blue;
}
.returingDataSearch {
  color: #616b7a;
  font-size: 0.85rem;
  font-family: "roboto";
}
.NoAdminDataForAdmin {
  height: calc(100% - 50px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 15px 0px;
  border-radius: 5px;
  .NoDataTextContainerForAdmin {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-top: 2rem;
    h1 {
      padding: 10px;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 600;
      font-size: 1.3rem;
      line-height: 21px;
      letter-spacing: 0.004em;
      text-transform: uppercase;
      color: #000000;
    }
    h3 {
      padding: 5px 10px;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 0.9;
      line-height: 18px;
      letter-spacing: 0.004em;
      color: #797979;
    }
  }
}
.RightBarSearchCustomerAccount {
  display: flex;
  align-items: center;
  img {
    margin-left: 0.4rem;
  }
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #129160;
    border-radius: 5px;
    padding: 5px 8px;
    color: white;
    font-size: 12px;
    border: none;
    outline: none;
    margin: 0.5rem  0.5rem 0rem 0.5rem;
  }
}
.underLineTextVerify {
  width: fit-content;
  color: blue;
  font-size: 0.8rem;
  font-family: "Roboto";
  border-bottom: 2px solid blue;
}
.FirstClild {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 0.8rem;
  gap: 0.2rem;
}
.returingDataVerify {
  font-weight: 400;
  font-family: "Roboto";
  font-size: 0.7rem;
  color: #797979;
}
.inputDivSearchCustomerAccount {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 15rem;
  height: 1.9rem;
  border-bottom: 1px solid black;
}
.input-fieldSearchCustomerAccount {
  outline: none;
  width: 100%;
  height: 100%;
  margin-left: 5px;
  font-size: 12px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  background-color: white;
  padding-left: 5px;
}

table {
  border-collapse: separate;
  border-spacing: 0 0.4rem;
  tbody {
    tr {
      margin: 5px 2px;
      background-color: #f8f8f8;
      border-radius: 5px;
    }
  }

  td {
    padding: 2px 10px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #1a3447;
  }
  th {
    padding: 2px 10px;
    p {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      color: #1a3447;
    }
  }
  span {
    font-family: "Roboto";
  }
  .MoreBTN {
    cursor: pointer;
    margin-left: 5%;
  }
}
