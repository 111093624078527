.UnsuccessfullContainer {
  width: 55rem;
  // height: 100%;
  .topItems {
    display: flex;
    justify-content: space-between;
    padding: 1rem 1.5rem;
    .Headings {
      display: flex;
      flex-direction: column;
      h3 {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 500;
        font-size: 1.2rem;
        line-height: 1rem;
        color: #26405e;
      }
      p {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #616b7a;
        padding-top: 0.5rem;
        padding-left: 0.2rem;
      }
    }
    .Cross {
      color: #26405e;
      cursor: pointer;
    }
  }
  .TableContainer {
    .TableHeading {
      width: 100%;
      display: flex;
      justify-content: space-between;
      p {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 0.9rem;
        line-height: 16px;
        color: #26405e;
      }
      img {
        width: 1.1rem;
        padding-left: 0.2rem;
        cursor: pointer;
      }
      .div1 {
        width: 15.6%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.5rem 0rem;
        padding-right: 0.5rem;
      }
      .div2 {
        width: 16.6%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.5rem 0rem;
        padding-right: 1.5rem;
      }
      .div3 {
        width: 14.6%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.5rem 0rem;
        padding-right: 4rem;
      }
      .div4 {
        width: 16.6%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.5rem 0rem;
        padding-right: 3rem;
      }
      .div5 {
        width: 18.6%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.5rem 0rem;
        padding-right: 2rem;
      }
      .div6 {
        width: 17.6%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.5rem 0rem;
        padding-right: 3rem;
      }
    }
    .TableItems {
      width: 97%;
      height: 2rem;
      display: flex;
      justify-content: space-between;
      //   border: 1px solid red;
      align-items: center;
      margin: 0.5rem 0rem;
      background-color: #fafafa;
      margin-left: 1.3rem;
      padding: 0px 1rem;
      p {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #616b7a;
      }
      .User {
        width: 16%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 0.7em;
        padding-right: 5.5rem;
        margin: 0 auto;

        p {
          text-align: center;
        }
      }
      .Password {
        width: 10.5%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 0.7em;
        margin: 0 auto;
        position: relative;
        right: 1.5rem;

        img {
          width: 1.1rem;
          cursor: pointer;
        }
      }
      .Date {
        width: 16%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 0.7em;
        margin: 0 auto;
        padding-right: 2rem;
      }
      .IP_Address {
        width: 16%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 0.7em;
        padding-right: 4.5rem;
        margin: 0 auto;
      }
      .Code {
        width: 16%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 0.7em;
        padding-right: 4rem;
        margin: 0 auto;
      }
      .Attempts {
        width: 16%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 0.7em;
        margin: 0 auto;
        padding-right: 2.8rem;
      }
    }
  }
  .TableItemsContainer {
    width: 100%;
    height: 20rem;
    overflow-y: scroll;
  }
}
