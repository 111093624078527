.Images_demo_containerDrag {
  width: 100%;
  height: 450px;
}
.Image_demoDrag {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  border-radius: 0.3rem;
  height: 450px;
  h3 {
    padding: 0.5rem 0.2rem;
    color: #000;
    font-size: 0.8rem;
    font-weight: "500";
    font-family: "roboto";
    text-align: "center";
    // margin-left: 44%;
    border-bottom: 2px solid #000;
    width: fit-content;
  }
}
.Image_demoDrag img {
  border-radius: 0.3rem;
  max-width: 100%;
  object-fit: cover;
  padding: 0.5rem 1rem;
}

.Image_scroll_containerDrag {
  //   width: 900px;
  // height: 400px;
  overflow-x: auto;
  overflow-y: auto;
  white-space: nowrap;
}

.slick-sliderDrag {
  width: 100%;
}

.slick-slideDrag {
  padding: 0 5px;
}
