@tailwind base;
@tailwind components;
@tailwind utilities;

/* Define standard variables and values for website */
$bgcolor: #fff;
$textcolor: #000;
$sidebarWidth: 250px;
$sidebarWidthHidden: 30px;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  font-size: 18px;
  @media screen and (max-width: 1400px) {
    font-size: 16px;
  }
  @media screen and (max-width: 1000px) {
    font-size: 14px;
  }
  @media screen and (max-width: 850px) {
    font-size: 12px;
  }
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: $bgcolor;
  color: $textcolor;
}

.whole-container {
  background-color: #e8e8e8;
  .inner-container {
    background-color: #e8e8e8;
    padding: 0.75rem;

    .search-area {
      background-color: #e8e8e8;
      border-bottom: 1px solid #aaa;
      display: flex;
      width: 10rem;
    }
    input {
      background-color: #e8e8e8;
      border-width: 0;
    }
  }
  .table-area {
    overflow-y: auto;
    display: flex;
    flex-direction: row;
  }
}

img {
  max-width: none;
}
