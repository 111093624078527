.DeleteSuccess {
  width: 19rem;
  height: 20rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  .cancel {
    display: flex;
    width: 85%;
    margin: 0 auto;
    justify-content: flex-end;
    // border: 1px solid red;
    margin-top: 1rem;
    cursor: pointer;
  }
  .DeleteFrame {
    width: 90%;
    margin-top: 1rem;
    margin: 0 auto;
    // border: 1px solid red;
  }
  .textSuccess {
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      display: flex;
      margin: 0 auto;
      font-size: 0.9rem;
      font-weight: 400;
      font-family: "Roboto";
      text-align: center;
      color: #26405e;
    }
  }
  .buttonDiv {
    display: flex;
    justify-content: flex-end;
    height: 2.5rem;
    gap: 1rem;
    align-items: center;
    margin-right: 1rem;
  }
  .buttonClose {
    background: #616b7a;
    border-radius: 4px;
    color: #ffffff;
    font-family: "Roboto";
    border-radius: 4px;
    font-style: normal;
    font-weight: 300;
    font-size: 0.7rem;
    line-height: 0.8rem;
    padding: 0.4rem 0.8rem;
    outline: none;
    border: none;
    cursor: pointer;
  }
}
@media (orientation: portrait) {
  .DeleteSuccess {
    width: 23rem;
    height: 22rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    .DeleteFrame {
      width: 20rem;
      img {
        width: 100%;
      }
    }
  }
}
