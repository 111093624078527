.EmailVerifyDiv {
  width: 30rem;
  height: 18rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  h1 {
    padding-top: 1.2rem;
    padding-left: 2rem;
    font-family: "roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 68.51%;
    color: #364670;
  }
  .emailDiv {
    width: 86%;
    height: 10rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: 0 auto;
    // border: 1px solid red;
    .emails {
      display: flex;
      height: 4.5rem;
      flex-direction: column;
      justify-content: space-around;
      // border: 1px solid red;
      p {
        font-family: "roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 68.51%;
        color: #364670;
      }
      input {
        width: 100%;
        height: 2.5rem;
        outline: none;
        padding-left: 0.7rem;
        border: 1px solid grey;
        border-radius: 0.5rem;
        color: #696974;
        font-size: 0.8rem;
      }
    }
  }
  .Buttons {
    display: flex;
    justify-content: flex-end;
    // border: 1px solid blue;
    margin-bottom: 0.5rem;
    gap: 1rem;
    padding-right: 2.1rem;
    .button2 {
      color: #fff;
      outline: none;
      background: #38ab7c;
      padding: 0.4rem 1.2rem;
      border-radius: 0.5rem;
      font-size: 0.9rem;
    }
    .button1 {
      padding: 0.2rem 0.5rem;
      border-radius: 0.5rem;
      outline: none;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 0.9rem;
      line-height: 16px;
      color: #00309f;
    }
  }
}
.otp-Form {
  width: 15rem;
  height: 18rem;
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 30px;
  gap: 20px;
  position: relative;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.082);
  border-radius: 15px;
}

.mainHeading {
  font-size: 1.1em;
  color: rgb(15, 15, 15);
  font-weight: 700;
}

.otpSubheading {
  font-size: 0.7em;
  color: black;
  line-height: 17px;
  text-align: center;
}

.inputContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

.otp-input {
  background-color: rgb(228, 228, 228);
  width: 30px;
  height: 30px;
  text-align: center;
  border: none;
  border-radius: 7px;
  caret-color: rgb(127, 129, 255);
  color: rgb(44, 44, 44);
  outline: none;
  font-weight: 600;
}

.otp-input:focus,
.otp-input:valid {
  background-color: rgba(127, 129, 255, 0.199);
  transition-duration: 0.3s;
}

.verifyButton {
  width: 100%;
  height: 30px;
  border: none;
  background-color: rgb(127, 129, 255);
  color: white;
  font-weight: 600;
  cursor: pointer;
  border-radius: 10px;
  transition-duration: 0.2s;
}

.verifyButton:hover {
  background-color: rgb(144, 145, 255);
  transition-duration: 0.2s;
}

.exitBtn {
  position: absolute;
  top: 5px;
  right: 5px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.171);
  background-color: rgb(255, 255, 255);
  border-radius: 50%;
  width: 25px;
  height: 25px;
  border: none;
  color: black;
  font-size: 1.1em;
  cursor: pointer;
}

.resendNote {
  font-size: 0.7em;
  color: black;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.resendBtn {
  background-color: transparent;
  border: none;
  color: rgb(127, 129, 255);
  cursor: pointer;
  font-size: 1.1em;
  font-weight: 700;
}
