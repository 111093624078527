.header-container {
  flex: 1;
  padding: 1.75rem 01rem;
  align-items: center;
  justify-content: flex-end;
  .searchBar {
    border-radius: 0.2rem;
    input {
      border-radius: 0.2rem;
    }
  }
  .admin-details-area {
    width: 12rem;
    .profile-pic {
      width: 2rem;
      height: 2rem;
      border-radius: 1rem;
      background: #dfdfdf;
    }
    .name {
      font-size: 0.9rem;
      display: block;
      color: #1a3447;
    }
    .admin {
      img {
        display: inline;
      }
      font-size: 0.8rem;
      color: #404c57;
    }
  }
  .NameContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .bell_notify {
    position: relative;
    bottom: 1.3rem;
    right: 1.9rem;
    z-index: 11;
    color: #4cab7b;
    font-size: 5.2rem;
    cursor: pointer;
  }
  .bell_notify_count {
    position: relative;
    bottom: 0.5rem;
    right: 1.9rem;
    z-index: 11;
    color: #c9372c;
    background: #f9f9f9;
    font-size: 0.7rem;
    cursor: pointer;
    font-weight: bold;
  }
}
