.DialogBoxOTP {
  width: 25rem;
  height: 12rem;
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  h3 {
    font-family: "roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 68.51%;
    display: flex;
    align-items: center;
    color: #364670;
    padding-top: 1rem;
    padding-left: 1.1rem;
    // border: 1px solid red;
  }
  .UserEmail {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    width: 23rem;
    margin-top: 1.5rem;
    margin-left: 1.2rem;
    .UserEmailText {
      font-family: "roboto";
      font-style: normal;
      font-size: 0.836rem;
      line-height: 68.51%;
      color: #364670;
      padding-bottom: 0.8rem;
    }
    input {
      outline: none;
      width: 100%;
      height: 2.2rem;
      background: #f0f0f0;
      border-radius: 8px;
      padding-left: 0.8rem;
      font-size: 0.8rem;
    }
  }
  .BottomOTPSec {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 1.5rem;
    gap: 2rem;
    justify-content: space-between;
    padding: 0 5%;
    // border: 1px solid red;
    .btnView {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      button {
        margin: 0 0.75rem;
      }
    }
    p {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 0.8rem;
      line-height: 1rem;
      color: #364670;
    }

    .closeButton {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 0.9rem;
      line-height: 16px;
      color: #00309f;
      border: none;
      outline: none;
    }
    .SendOTP {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 1.9rem;
      padding: 0.3rem 0.5rem;
      background: #38ab7c;
      border: 1px solid #c0c0c0;
      border-radius: 4px;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 0.9rem;
      color: #ffffff;
      border: none;
      outline: none;
    }
  }
}
