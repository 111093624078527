.DeleteContainer {
  background-color: #fff;
  .Cancelicon {
    width: 0.6rem;
    align-self: flex-end;
    margin-top: 1rem;
    margin-right: 1.5rem;
    cursor: pointer;
  }
  .DeleteImage {
    align-self: center;
  }
  .CancelAndDeleteBTN {
    width: 55%;
    align-self: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    h4 {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
    }
    h5 {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #00309f;
      cursor: pointer;
    }
    button {
      background: #ff3232;
      padding: 0.5rem 0.8rem;
      outline: none;
    }
  }
}
