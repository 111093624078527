$primary-color: #007bff;
$dropdown-background: white;
$dropdown-border-color: #ddd;
$dropdown-hover-background: #f1f1f1;
$button-padding: 10px;
$dropdown-padding: 10px;
$dropdown-margin-top: 5px;

.dropdown-content1 {
  position: fixed;
  // top: 128px; // Change this to a relative value if necessary
  // left: 72%; // Center horizontally
  // transform: translateX(-50%); // Center horizontally
  background-color: $dropdown-background;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 999999999;
  margin-top: $dropdown-margin-top;
  padding: $dropdown-padding;
  border: 1px solid $dropdown-border-color;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      padding: 8px 12px;
      cursor: pointer;
      font-weight: 400;
      font-size: 0.8rem;
      font-family: "Roboto";

      &:hover {
        background-color: $dropdown-hover-background;
      }
    }
  }
}

@media (max-width: 768px) {
  .dropdown-content1 {
    top: auto; // Reset any top value
    left: auto; // Reset any left value
    right: 10px; // Adjust for small screens
    transform: none; // Remove centering
    width: calc(100% - 20px); // Full width with some padding
  }
}

.ContainerForSearchBussiness {
  height: calc(100% - 140px);
  padding-bottom: 0.3rem;
  // border: 1px solid red;

  .NoAdminDataForSearchBusAccount {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-top: 2rem;

    h1 {
      padding: 10px;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 600;
      font-size: 1.3rem;
      line-height: 21px;
      letter-spacing: 0.004em;
      text-transform: uppercase;
      color: #000000;
    }

    h3 {
      padding: 5px 10px;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 0.9rem;
      line-height: 19px;
      letter-spacing: 0.004em;
      color: #797979;
    }
  }
}

.inner-containerForBusiness {
  padding-top: 10px;
  padding-left: 10px;
}

.FirstClildSearchBusiness {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-left: 0.8rem;
  gap: 0.2rem;
}

.underLineTextSearchBusiness {
  width: 100%;
  color: blue;
  font-weight: 400;
  font-size: 0.8rem;
  font-family: "Roboto";
  cursor: pointer;
  border-bottom: 2px solid blue;
}

.returingDataSearchBusiness {
  font-weight: 400;
  font-size: 0.7rem;
  font-family: "Roboto";
  color: #616b7a;
}

.HeadingAndSearchForBusiness {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0rem;
  width: 100%;

  h1 {
    color: #26405e;
    font-weight: 600;
    font-family: "Roboto";
    line-height: 22px;
    padding-left: 0.2rem;
    text-align: center;
    font-size: 20px;
    margin-top: 5px;
  }
}

.DataContainer {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.RightBarForBusiness {
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
  align-items: center;
  // width: 39.5rem;
  // height: 35px;
}

.locationByForBusiness {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #129160;
  border-radius: 5px;
  padding: 4px 8px;
  color: white;
  font-size: 12px;
  border: none;
  outline: none;
}

.SearchByForBusiness {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #42426e;
  border-radius: 5px;
  padding: 0.4rem 8px;
  color: white;
  font-size: 12px;
  border: none;
  outline: none;
}

.inputDivForBusiness {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 15rem;
  height: 1.9rem;
  margin-right: 1rem;
  border-bottom: 1px solid black;

  input {
    outline: none;
    width: 100%;
    height: 100%;
    margin-left: 5px;
    font-size: 12px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    // background-color: white;
    background: none;
    padding-left: 5px;
  }
}

table {
  border-collapse: separate;
  border-spacing: 0 0.4rem;

  tbody {
    tr {
      margin: 5px 2px;
      background-color: #f8f8f8;
      border-radius: 5px;
    }
  }

  td {
    padding: 2px 10px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    /* identical to box height, or 157% */
    /* Dark green */
    color: #1a3447;
  }

  th {
    padding: 2px 10px;

    p {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      color: #1a3447;
    }
  }

  span {
    font-family: "Roboto";
  }
}
