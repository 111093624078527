.cardContainer {
  box-sizing: border-box;
  width: 100%;
  padding: 1.25rem 0.3rem 0.05rem 0.3rem;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  margin-bottom: 0.75rem;
  border-radius: 10px;
  height: 318px;
  z-index: 9999;
  background-color: #fff;
  overflow: hidden;
  .Loader {
    width: 1.5rem;
    display: flex;
    margin: 0 auto;
  }
  .secondAcc {
    cursor: pointer;
    background: #ffff28;
    border-radius: 8px;
    font-family: "Roboto", sans-serif;
    font-weight: bolder;
    font-size: 0.7rem;
    color: #465b74;
    width: fit-content;
    padding: 4px 0.5rem;
  }

  .card__inner-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
    width: 100%;
    .card__image-area {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;

      .ImageContainer {
        border-radius: 50%;
        margin-right: 3.2rem;
      }
      .userImg {
        height: 4rem;
        width: 4rem;
        border-radius: 50%;
      }
    }
.blured{
  pointer-events: none; /* Prevent interaction */
  user-select: none;
}
    .admin-id {
      font-size: 0.8rem;
      line-height: 16px;
      color: #38ab7c;
      text-align: center;
    }
    .ip-address-number {
      font-family: "Roboto";
      padding: 0.3rem 0;
      font-size: 0.6rem;
      font-weight: 500;
      color: #454b90;
      margin-left: 1rem;
    }
    .Master_AdminEmail {
      font-family: "Roboto";
      padding: 0.3rem 0;
      font-size: 0.6rem;
      font-weight: 500;
      color: #454b90;
      margin-left: 1rem;
    }
    .RegenerateOTP {
      font-family: "Roboto";
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.3rem 0;
      font-size: 0.85rem;
      font-weight: 500;
      color: #343a7f;
      height: 1.5rem;
      // margin-left: 0.2rem;
      .WrittenPassword {
        letter-spacing: 0.1rem;
        font-size: 0.65rem;
        font-weight: 500;
        color: #343a7f;
        cursor: pointer;
      }
      .noneStar {
        font-size: 0.85rem;
        font-weight: 500;
        color: #343a7f;
        margin-left: 0.2rem;
        letter-spacing: 0.3rem;
      }
    }
    .badge_container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.2rem 0.3rem;
      width: 100%;
      .badge_n_more {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 0.4rem;
        width: 100%;
      }
      .profile_conateiner {
        width: 20%;
      }
      .userImg {
        width: 2.2rem;
        height: 2.2rem;
        border-radius: 50%;
        border: 1px solid gray;
      }
      .MoreBTN {
        color: #aaa;
        width: 1.5rem;
        cursor: pointer;
      }
    }

    // switch admin /
    .admin_btn {
      width: 50%;
      height: 35px;
      background: #6ab193;
      color: #fff;
      border: none;
      outline: none;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      border-radius: 5px;
    }

    .admin_btn span {
      font-size: 0.7rem;
      text-transform: uppercase;
      letter-spacing: 1px;
      transition: top 0.5s;
    }

    .admin_btn-text-one {
      position: absolute;
      width: 100%;
      top: 50%;
      left: 0;
      cursor: pointer;
      font-family: "roboto";
      font-weight: 500;
      font-size: 0.7rem;
      line-height: 14px;
      color: #fff;
      transform: translateY(-50%);
    }
    .master_btn {
      width: fit-content;
      height: 35px;
      background: #6ab193;
      border: none;
      outline: none;
      border-radius: 5px;
    }
    .master_admin_text {
      font-family: "roboto";
      font-weight: 500;
      font-size: 0.6rem;
      line-height: 14px;
      color: #fff;
      overflow: hidden;
      padding: 0rem 1rem;
    }
    .admin_btn-text-two {
      position: absolute;
      width: 100%;
      top: 150%;
      left: 0;
      transform: translateY(-50%);
    }

    .admin_btn:hover .admin_btn-text-one {
      top: -100%;
    }
    .admin_btn:hover {
      border: 1.5px solid #6ab193;
      border-radius: 5px;
      width: 50%;
      height: 35px;
      color: #6ab193;
      background-color: #fff;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .admin_btn:hover .admin_btn-text-two {
      text-transform: capitalize;
      top: 50%;
      font-family: "roboto";
      font-weight: 500;
      font-size: 0.7rem;
      line-height: 14px;
      color: #6ab193;
    }

    // Bottom details container

    .bottom_Details {
      width: 95%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-self: center;
      margin: 0.5rem;
      background-color: #f4f9f7;
      border-radius: 10px;
      overflow: hidden;
      border: 0.5px solid #6ab193;
      .Common_data {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0rem 0.2rem;
        .Headings {
          font-family: "roboto";
          font-size: 0.7rem;
          color: #26405e;
          width: 25%;
          padding-left: 0.2rem;
        }
      }
      .admin-name {
        font-family: "roboto";
        font-size: 0.7rem;
        color: #616b7a;
        padding: 0rem;
        width: 78%;
        display: flex;
        justify-content: flex-start;
      }
    }
    .WrittenPassword {
      letter-spacing: 0.1rem;
      font-size: 0.65rem;
      font-weight: 500;
      color: #343a7f;
      cursor: pointer;
    }
    .noneStar {
      font-size: 0.85rem;
      font-weight: 500;
      color: #343a7f;
      margin-left: 0.2rem;
      letter-spacing: 0.2rem;
    }
    .user_id_container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 0.2rem 2.1rem 0.2rem 0.5rem;
      .StatusContainer {
        display: flex;
        flex-direction: column;
        .user_id_text {
          font-family: "roboto";
          font-style: normal;
          font-weight: 600;
          font-size: 0.7rem;
          line-height: 14px;
          border-bottom: 4px solid #38ab7c;
        }
      }
    }
    .user_id_container2 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 0.2rem 2.1rem 0.2rem 0.5rem;
      .StatusContainer {
        display: flex;
        flex-direction: column;
        gap: 0.2rem;
        .user_id_text {
          font-family: "roboto";
          font-style: normal;
          font-weight: 600;
          font-size: 0.7rem;
          line-height: 14px;
          border-bottom: 4px solid red;
        }
      }
    }
    .CurrentStatus {
      font-family: "Roboto";
      font-size: 0.7rem;
      position: relative;
      bottom: 5px;
    }
    .masteradmin1 {
      // background: #c7e2d6;
      display: flex;
      border-radius: 0.5rem;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin: 0.1rem auto;
      margin-top: 0.5rem;
      cursor: pointer;
      .masteradmin_Text1 {
        font-family: "Roboto";
        padding: 0.4rem 0.6rem;
        font-style: normal;
        font-weight: 500;
        font-size: 0.7rem;
        line-height: 14px;
        color: #081383;
      }
    }
    .sentOtpMain {
      display: flex;
      justify-content: center;
      align-items: center;
      width: fit-content;
      background-color: #f4f9f7;
      border-radius: 5px;
      // background-color: #6ab193;
      gap: "1rem";
      padding: 0.1rem;
      position: relative;
      bottom: 8px;
    }

    .SendText {
      font-family: "roboto";
      font-size: 0.6rem;
      color: #555;
      padding: 0.3rem 0.2rem;
    }
    // .mainOtp{
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // border:1px solid red
    // }
    .DetailContainer {
      width: 100%;
    }
    .passWordContainer {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      align-self: center;
      height: 1.5rem;
      font-size: 0.85rem;
      font-weight: 500;
      color: #343a7f;
      width: 80%;
      .password_digit {
        width: 55%;
        display: flex;
        justify-content: flex-start;
      }
      .WrittenPassword {
        letter-spacing: 0.1rem;
        font-size: 0.65rem;
        font-weight: 500;
        color: #343a7f;
        cursor: pointer;
        margin-bottom: 0.2rem;
      }
      .Passwords {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: "Roboto";
        padding: 0.3rem 0;
        font-size: 0.7rem;
        font-weight: 500;
        color: #081383;
        margin-left: 0.5rem;
        .noneStar {
          font-size: 0.85rem;
          font-weight: 500;
          color: #343a7f;
          // margin-left: 0.5rem;
          letter-spacing: 0.3rem;
        }
      }
      .eyeButton {
        outline: none;
        border: none;
        margin-right: 1rem;
        margin-bottom: 0.2rem;
        .Eye {
          width: 100%;
          outline: none;
          border: none;
        }
      }
    }
    .HoverPassword_Container {
      display: flex;
      margin: 0 auto;
      background-color: #c7e2d6;
      height: 1.5rem;
      border-radius: 0.5rem;
      z-index: 1;
      position: relative;
      transform: translateX(-50%);
      .HoverPassword {
        color: #081383;
        font-family: "Roboto";
        padding: 0.2rem 0.8rem;
        margin: 0 auto;
        font-size: 0.7rem;
      }
    }
    .OTPContainer {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 0.85rem;
      font-weight: 500;
      color: #343a7f;
      height: 1.5rem;
      width: 80%;
      .OTP_digit {
        display: flex;
        width: 55%;
        justify-content: flex-start;
        .WrittenPassword {
          letter-spacing: 0.1rem;
          font-size: 0.65rem;
          font-weight: 500;
          color: #343a7f;
          cursor: pointer;
          margin-bottom: 0.2rem;
        }
      }
      .eyeButton_OTP {
        outline: none;
        border: none;
        margin-right: 0.9rem;
        margin-bottom: 0.2rem;
        .Eye {
          padding: 0;
          width: 100%;
        }
      }
    }
  }
  .NullableContainer {
    display: flex;
    height: 1.3rem;
  }
}
.cardContainer:hover {
  transform: scale(1.03);
  border-radius: 0.8rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
@media (orientation: portrait) {
  .cardContainer {
    // height: 18rem;
    width: 11rem;
    display: flex;
    background-color: #fff;
    padding: 0.8rem 0.75rem;
    margin-top: 0.4rem;
  }
  .card__inner-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
    width: 100%;

    .admin-id {
      font-size: 14px;
      line-height: 16px;
      color: #38ab7c;
      text-align: center;
      padding-top: 0.3rem;
    }

    .ip-address-text {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      color: #26405e;
    }
    .ip-address-number {
      display: flex;
      align-self: center;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      padding-top: 0.4rem;
      color: #37516f;
    }
    .RegenerateOTP {
      font-family: "Roboto";
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.3rem 0;
      font-size: 0.7rem;
      font-weight: 500;
      color: #343a7f;
      margin-left: 0.2rem;
    }
    .passWordContainer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: 1.5rem;
      font-family: "Roboto";
      font-size: 0.7rem;
      font-weight: 500;
      color: #343a7f;
      width: 80%;
      border: 1px solid black;
      .Passwords {
        font-family: "Roboto";
        padding: 0.3rem 0;
        font-size: 0.7rem;
        font-weight: 500;
        color: #081383;
      }
      .WrittenPassword {
        font-family: "Roboto";
        letter-spacing: 0.1rem;
        font-size: 0.65rem;
        font-weight: 500;
        color: #343a7f;
        cursor: pointer;
      }
      .eyeButton {
        outline: none;
        border: none;
        margin-right: 1rem;
        .Eye {
          padding: 0;
          width: 100%;
          // position: relative;
          // right: 0;
        }
      }
    }

    .OTPContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.3rem 0;
      font-size: 0.7rem;
      font-weight: 500;
      color: #343a7f;
      height: 1.5rem;
      width: 90%;
    }
    .masteradmin {
      background: #6ab193;
      border-radius: 20px;
      text-align: center;
      &__text {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        /* identical to box height */
        color: #000000;
      }
    }
    .NullableContainer {
      display: flex;
      height: 5.3rem;
    }
  }
}
//
