
.ViewAdminList{
  display: flex;
  // justify-content: center;
  // align-items: center;
  gap:10px;
  flex-wrap: wrap;
}
.Loader {
  width: 1.5rem;
  display: flex;
  margin: 0 auto;
}
.OTPContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 0.85rem;
  font-weight: 500;
  color: #343a7f;
  height: 1.5rem;
  width: 80%;
  .OTP_digit {
    display: flex;
    width: 55%;
    justify-content: flex-start;
    .WrittenPassword {
      letter-spacing: 0.1rem;
      font-size: 0.65rem;
      font-weight: 500;
      color: #343a7f;
      cursor: pointer;
      margin-bottom: 0.2rem;
    }
  }
  .eyeButton_OTP {
    outline: none;
    border: none;
    margin-right: 0.9rem;
    margin-bottom: 0.2rem;
    .Eye {
      padding: 0;
      width: 100%;
    }
  }
}
.table-areaForTempBussiness {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 0.4rem;
  .HeaderDetailTemp {
    font-size: 0.95rem;
    font-weight: 500;
    font-family: "Roboto";
  }
}
.AdminManagementMainContainer {
  height: calc(100% - 140px);
  padding: 0.5rem;
  .TopContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h1 {
      color: #26405e;
      font-weight: 500;
      font-family: "Roboto";
      line-height: 22px;
      text-align: center;
      font-size: 20px;
      margin-top: 5px;
      padding-left: 0.5rem;
      font-size: 1.2rem;
    }
    .RightSideContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      .search-area {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid grey;
        input {
          padding: 0.2rem 0.6rem;
          background: none;
        }
      }

      .createAdminBtn {
        margin-left: 0.5rem;
      }
      .mailAdminBtn {
        margin-left: 0.5rem;
      }
    }
  }
  .NoAdminData {
    height: calc(100% - 50px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 15px 0px;
    border-radius: 5px;
    .NoDataTextContainer {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      margin-top: 2rem;
      h1 {
        padding: 10px;
        font-family: "roboto";
        font-style: normal;
        font-weight: 600;
        font-size: 1.3rem;
        line-height: 21px;
        letter-spacing: 0.004em;
        text-transform: uppercase;
        color: #000000;
      }
      h3 {
        padding: 5px 10px;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 0.9;
        line-height: 18px;
        letter-spacing: 0.004em;
        color: #797979;
      }
    }
  }
}

.RightSideContainer {
  margin-right: 1rem;
  input {
    outline: none;
  }
}
.SearchArea {
  input {
    height: 2.2rem;
    padding: 0.5rem;
  }
}
.createAdminBtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.6rem 0.8rem;
  height: 2.2rem;
  gap: 0.3rem;
  background: #1a3447;
  border-radius: 3.35438px;
  img {
    width: 1.2rem;
  }
  .text {
    font-family: "roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 110%;
    color: #ffffff;
    text-align: center;
  }
}

//-----iPad------

@media (orientation: portrait) {
  .TopContainer {
    // border: 1px solid blue;
    display: flex;
    flex-flow: row wrap;
    overflow-y: auto;
    h1 {
      color: #26405e;
      font-weight: 600;
      font-family: "Roboto";
      line-height: 22px;
      text-align: center;
      font-size: 20px;
      margin-top: 5px;
      padding-left: 0.5rem;
    }
    .RightSideContainer {
      // border: 1px solid black;
      width: 38rem;
      height: 2.5rem;
      padding: 0.8rem;
      display: flex;
      justify-content: space-between;
      .search-area {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid grey;
        input {
          padding: 0.2rem 0.6rem;
          background: none;
        }
      }
      .search-area:nth-child(1) {
        // background-color: red;
        width: 9rem;
      }
      .createAdminBtn {
        width: 16rem;
        cursor: pointer;
        span {
          font-size: 0.94rem;
          padding-right: 0.2rem;
        }
      }
      .mailAdminBtn {
        width: 3rem;
        outline: none;
        cursor: pointer;
      }
    }
  }
}

//--------Mobile------
@media only screen and (max-width: 415px) {
  .card {
    height: 16rem;
    width: 100%;
    background-color: #fff;
    padding: 1.25rem 0.75rem;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    margin-bottom: 0.75rem;
    // border: 1px solid red;
    .card__inner-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      height: 100%;
      width: 100%;

      .card__image-area {
        display: flex;
        justify-content: space-between;
        width: 100%;

        .userImg {
          height: 3rem;
          width: 3rem;
        }
      }

      .admin-name {
        font-size: 16px;
        line-height: 19px;
        /* identical to box height */

        color: #000000;

        /* Inside auto layout */

        flex: none;
        order: 0;
        flex-grow: 0;
        text-align: center;
      }
      .admin-id {
        font-size: 14px;
        line-height: 16px;

        /* button linear */

        color: #38ab7c;
        text-align: center;
      }

      .ip-address-text {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        text-align: center;

        /* icon color */

        color: #26405e;
      }
      .ip-address-number {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        text-align: center;

        /* icon color */

        color: #37516f;
      }

      .masteradmin {
        padding: 4px 8px;
        gap: 10px;

        width: 92px;
        height: 22px;

        background: #f3f3f3;
        border-radius: 20px;
        text-align: center;

        &__text {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          /* identical to box height */

          color: #000000;
        }
      }
    }
  }
  .TopContainer {
    // border: 1px solid blue;
    h1 {
      color: #26405e;
      font-weight: 500;
      font-family: "Roboto";
      line-height: 22px;
      text-align: center;
      font-size: 20px;
      margin-top: 5px;
      padding-left: 0.5rem;
      font-size: 1.2rem;
    }
    .RightSideContainer {
      // border: 1px solid black;
      width: 22rem;
      height: 2rem;
      display: flex;
      justify-content: space-between;
      margin: 0;
      padding: 0;
      .search-area {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid grey;
        input {
          padding: 0.2rem 0.6rem;
          background: none;
        }
      }
      .search-area:nth-child(1) {
        // background-color: red;
        width: 4.5rem;
      }
      .createAdminBtn {
        width: 4.2rem;
        cursor: pointer;
        display: flex;
        justify-content: flex-start;
        img {
          width: 1rem;
        }
        span {
          font-size: 0.6rem;
          padding-right: 0.2rem;
        }
      }
      .mailAdminBtn {
        width: 2rem;
        outline: none;
        cursor: pointer;
        img {
          width: 1.2rem;
        }
      }
    }
  }
}
