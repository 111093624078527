.BusinessLicenseMainConatiner {
  display: flex;
  flex-direction: row;
  height: calc(100% - 7.5rem);
  overflow-x: auto; 
  overflow-y: hidden; 
  .inner-containerForBusinessLicence {
    height: 100%;
    // overflow-y: auto;
    // flex-direction: column;
    width: 150%; 
    align-items: center;
    padding: 0 0.5rem;
    white-space: nowrap; 
  }
  .inner-containerForBusinessLicence_with_message {
    height: 100%;
    width: calc(100% - 22rem);
    padding: 0.5rem;
  }
  .HeadingAndSearchBusinessLicense {
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    padding: 5px 0px;
    gap: 0.4rem;
    h1 {
      color: #26405e;
      font-weight: 600;
      font-family: "Roboto";
      line-height: 22px;
      // text-align: center;
      font-size: 20px;
      margin-top: 5px;
    }
  }
  .myControlClassName {
    width: 70%;
    height: 2rem;
  }
  .RightBarBusinessLicense {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .RightBarBusinessLicenseLeftItems {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0.2rem 0;
      p {
        color: #26405e;
        font-weight: 400;
        font-size: 0.8rem;
        font-family: "Roboto";
        text-align: center;
      }
    }
  }
  .FirstClildBusiness {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 0.5rem;
    gap: 0.2rem;
  }
  .returingDataBusLicence {
    color: #616b7a !important;
    font-size: 0.7rem !important;
    font-family: "roboto" !important;
  }
  .underLineTextBusinessLicence {
    width: 100%;
    color: blue;
    font-weight: 400;
    font-size: 0.8rem;
    font-family: "Roboto";
    cursor: pointer;
    border-bottom: 2px solid blue;
  }
  .returingDataBusLicenceDate {
    font-weight: 400;
    font-size: 0.7rem;
    font-family: "Roboto";
    color: #616b7a;
  }
  .table-areaFOrBussinessLicence {
    height: calc(100vh - 60px);
    display: flex;
    flex-direction: column;
  }
  .AddIcon-styleLicense {
    font-size: 1.2rem;
  }
  .UserDetails {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .NoAdminDataForBusLicenceVerify {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-top: 2rem;
    h1 {
      padding: 10px;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 600;
      font-size: 1.3rem;
      line-height: 21px;
      letter-spacing: 0.004em;
      text-transform: uppercase;
      color: #000000;
    }
    h3 {
      padding: 5px 10px;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 0.9;
      line-height: 18px;
      letter-spacing: 0.004em;
      color: #797979;
    }
  }
}
.Header_Blicence_verification {
  font-family: "Roboto" !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 22px !important;
  color: #1a3447 !important;
}
.Icon-containerLicense {
  // width: 15rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 2.2rem;
  padding: 0px 10px;
  gap: 2rem;
}
.iconsLicense {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 6rem;
  height: 1.9rem;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
  margin: 0px 3px;
}
.Input-and-iconsLicense {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 8rem;
  height: 1.9rem;
  cursor: pointer;
  border-bottom: 1.5px solid #070647;
  // border: 1px solid red;
}
.Input-fieldLicense {
  background-color: transparent;
  width: 4rem;
  outline: none;
  font-size: 14px;
  margin-top: 5px;
  margin-right: 40px;
}
.Create-iconsLicense {
  background-color: #060649;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.9rem;
  border-radius: 5px;
  padding: 0px 1rem;
  cursor: pointer;
  margin-right: 10px;
  p {
    color: #fff;
    font-size: 1rem;
  }
}

.AddIcon-styleLicense {
  color: white;
  border: 1px solid #d4cfcf;
  border-radius: 50%;
  margin-right: 10px;
}

.locationByBusinessLicense {
  display: flex;
  justify-content: center;
  align-items: center;
  // background-color: #129160;
  border-radius: 5px;
  padding: 4px 8px;
  color: white;
  font-size: 12px;
  border: none;
  outline: none;
}
.SearchByBusinessLicense {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #42426e;
  border-radius: 5px;
  padding: 4px 8px;
  color: white;
  font-size: 12px;
  border: none;
  outline: none;
}
.inputDivBusinessLicense {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 15rem;
  height: 1.9rem;
  // border-radius: 5px;
  border-bottom: 1px solid black;
}
.input-fieldBusinessLicense {
  outline: none;
  width: 100%;
  height: 100%;
  margin-left: 5px;
  font-size: 12px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  background-color: white;
  padding-left: 5px;
}
.HeaderDetailBusinessLicense {
  height: 60px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 1.5rem;
  font-size: 0.9rem;
  font-weight: 500;
  font-family: "Roboto";
}
.userDIvBusinessLicense {
  width: 195px;
  // border: 1px solid blue;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.userDIvBusinessLicense p {
  margin-left: 4rem;
  font-family: "Roboto";
}

.DateDivBusinessLicense {
  width: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // border: 1px solid blue;
  position: relative;
  right: 9px;
}

.LocationDivBusinessLicense {
  width: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // border: 1px solid yellow;
  position: relative;
  right: 1.5rem;
  p {
    margin-left: 5px;
  }
}
.AddressDivBusinessLicense {
  width: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // border: 1px solid rgb(129, 23, 5);
  position: relative;
  right: 1rem;
}
.EmailDivBusinessLicense {
  width: 125px;
  display: flex;
  justify-content: center;
  align-items: center;
  // border: 1px solid green;
  position: relative;
  left: 3rem;
}
.VideosBusinessLicense {
  width: 140px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  // border: 1px solid green;
  position: relative;
  left: 3rem;
}
.VerifiedBusinessLicense {
  width: 80px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  // border: 1px solid green;
}
.MessageBusinessLicense {
  width: 120px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  // border: 1px solid green;
  position: relative;
}
.MessageSidebar {
  width: 22rem;
  height: calc(100vh - 150px);
  background-color: #ffffff;
  padding: 0;
  margin: 0;
}
.MessageSidebarNone {
  display: none;
  width: 22rem;
  height: calc(100vh - 70px);
  background-color: #ffffff;
  padding: 0;
  margin: 0;
}
.MessageTopHeading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.7rem;
  align-items: center;
  border-bottom: 1px solid #ffffff;
  p {
    color: #0c064d;
    font-size: 1.1rem;
  }
}
.MessageBox {
  p {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
  }
  .UploadInstuctions {
    // style={{ fontSize: 16, padding: 8 }}
    font-size: 1rem;
    padding: 0.5rem;
  }
}
.Messages {
  p {
    font-size: 1rem;
    padding: 0.5rem;
  }
}

table {
  border-collapse: separate;
  border-spacing: 0 0.4rem;
  tbody {
    tr {
      margin: 5px 2px;
      background-color: #ffffff;
      border-radius: 5px;
    }
  }

  td {
    padding: 2px 10px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    /* identical to box height, or 157% */

    /* Dark green */

    color: #1a3447;
  }
  th {
    padding: 2px 10px;
    p {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      color: #1a3447;
    }
  }
  span {
    font-family: "Roboto";
  }
}
.myDropdownMenuClass {
  left: auto !important;
  right: -100 !important;
  top: 0 !important;
  z-index: 99999
}
