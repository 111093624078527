.msgContainer {
}

.setbusy {
  background: #f2f5fa;
  // opacity: 0.2; /* Set opacity to make it look disabled */
  pointer-events: none; /* Prevent interaction */
  user-select: none;
}

.setfree {
  background: #f2f5fa;
}
.CustomerMessages {
  display: flex;
  flex-direction: row;
  padding: 21px 15px;
  gap: 12px;
  width: 100%;
  height: 75px;

  margin-top: 2px;
  //   border: 1px solid blue;
  cursor: pointer;

  .divOne {
    width: 10%;
    height: 100%;
    background-color: #fff;
    border-radius: 50%;
    position: relative;
    left: 0px;
    top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #38ab7c;
    color: #fff;
  }
}
.divTwo {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  width: 100%;
  .upperDiv {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    gap: 179px;
    width: 100%;
    height: 22px;
    h6 {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 22px;
      cursor: pointer;
      /* identical to box height, or 183% */
      text-decoration-line: underline;
      text-transform: uppercase;
      /* main dark */
      color: #00309f;
    }
    p {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 22px;
      /* identical to box height, or 183% */
      text-transform: uppercase;
      /* text color */
      color: #616b7a;
    }
  }
}
.lowerDIv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  // padding: 0px;
  width: 100%;
  height: 44px;
  //   border: 1px solid black;
  p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #26405e;
    width: 100%;
  }
}

.CustomerMessages.active {
  background-color: #98e5bd;
}
.unread-count {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #ff0000;
  color: #fff;
  font-size: 12px;
  position: absolute;
  top: 0;
  right: 0;
}
.unread_counte {
  display: inline-block;
  background-color: #38ab7c;
  color: white;
  font-size: 12px;
  padding: 0px 5px;
  border-radius: 50%;
  margin-left: 5px;
}

.msgCount {
  width: 80%;
  display: flex;
  justify-content: end;
}
.BlurCount {
  display: none;
}

.msgRec {
  width: 20%;
  background: #00b66a;
  border-radius: 50%;
  text-align: center;
  color: #fff5f5;
}
