.inner-container-Announcement {
  height: calc(100% - 15%);
  overflow-y: scroll;
  padding-bottom: 0.5rem;
  // width: 100%;
}
.NoDataFound {
  justify-content: center;
  align-items: center;
  display: flex;
  height: 81vh;
  width: 81vw;
  .imageContainer {
    width: 100%;
    height: 100%;
    img {
      height: 100%;
      display: flex;
      margin: 0 auto;
    }
  }
}
.topBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5%;
  width: 98%;
  h1 {
    color: #26405e;
    font-weight: 500;
    font-family: "Roboto";
    line-height: 22px;
    text-align: center;
    font-size: 24px;
    margin-top: 5px;
    padding-left: 0.8rem;
  }
}
.rightIconContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 3rem;
  .search-area {
    display: flex;
    justify-content: space-between;
    align-self: center;
    align-items: center;
    border-bottom: 1px solid grey;
    margin-right: 0.5rem;
    input {
      padding: 0.5rem 0.2rem;
      background: none;
      outline: none;
      font-family: "roboto";
      font-size: 0.8rem;
    }
  }
  .pdf {
    height: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0.625rem auto;
    background-color: #1a3447;
    border-radius: 3.35438px;
    cursor: pointer;
    margin-right: 0.5rem;
    .Icons {
      color: #ffffff;
      text-align: center;
      padding: 0px 0.4rem;
      font-size: 1.8rem;
    }
    p {
      font-size: 0.8rem;
      color: white;
      padding: 0px 0.5rem;
    }
  }
  .pdf2 {
    height: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0.625rem auto;
    background-color: #1a3447;
    border-radius: 3.35438px;
    cursor: pointer;
    .Icons {
      color: #ffffff;
      text-align: center;
      padding: 0px 0.4rem;
      font-size: 1.8rem;
    }
    p {
      font-size: 0.9rem;
      color: white;
      padding: 0px 0.5rem;
    }
  }
}
.itemIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  .editIcon {
    // color: white;
    cursor: pointer;
    display: flex;
    margin: 0 auto;
    text-align: center;
    padding: 0px 5px;
    color: #26405e;
    text-align: center;
    padding: 0px 3px;
    font-size: 30px;
  }
}

.CardContainer {
  width: 99%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, auto);
  gap: 0.5rem;
  margin-left: 0.5rem;
  // overflow-y: scroll;
}

.cards {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: auto;
  background: #ffffff;
  border: 1px solid #f2f5fa;
  border-radius: 0.625rem;
}
.cards:hover {
  transform: scale(1.03);
  border-radius: 0.625rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.topitems {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.625rem 10px;
  margin: 5px auto;
}

.itemText {
  font-size: 0.9rem;
  font-weight: 500;
}
.itemIcon {
  width: 34px;
  background-color: #f2f5fa;
  border-radius: 7px;
  cursor: pointer;
  //  border: 1px solid red;
}

._showAndLess {
  display: flex;
  flex-direction: column;
  width: 98%;
  color: #616b7a;
  font-family: "Roboto";
  font-size: 0.8rem;
  line-height: 18px;
  padding: 0.8px 0.4rem;
}

.SeeMore {
  cursor: pointer;
  margin-top: 0.5rem;
  margin-right: 0.5rem;
  color: #0760e6;
}
.SeeLess {
  color: #0760e6;
  font-size: 1.2rem;
  cursor: pointer;
  margin-top: 0.5rem;
  margin-right: 0.5rem;
}
p:nth-child(3) {
  margin-left: 2%;
  color: #616b7a;
  font-weight: 700;
  font-size: 0.8rem;
  line-height: 1rem;
  padding: 0.5rem 0.6rem;
}

//----------
//Media Querys---------For Ipad--------------
@media (orientation: portrait) {
  .inner-container-Announcement {
    height: calc(100% - 4%);
  }
  .topBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5%;
    width: 100%;
    height: 4rem;
    // border: 1px solid orange;
    h1 {
      text-align: center;
      font-weight: 600;
      margin-left: 0.625rem;
      font-size: 22px;
    }
  }
  .rightIconContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 19.5rem;
    height: 3rem;
    margin-right: 1.1%;
    .search-area {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid grey;
      input {
        padding: 0.2rem 0.6rem;
        background: none;
        outline: none;
      }
    }
    .pdf {
      height: 2.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0.625rem auto;
      background-color: #1a3447;
      border-radius: 3.35438px;
      cursor: pointer;
      .Icons {
        color: #ffffff;
        text-align: center;
        padding: 0px 5px;
        font-size: 30px;
      }
      p {
        font-size: 15;
        color: white;
        padding: 0px 8px;
      }
    }
    .pdf2 {
      height: 2.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0.625rem auto;
      background-color: #1a3447;
      border-radius: 3.35438px;
      cursor: pointer;
      .Icons {
        color: #ffffff;
        text-align: center;
        padding: 0px 5px;
        font-size: 30px;
      }
      p {
        font-size: 15;
        color: white;
        padding: 0px 8px;
      }
    }
  }

  .CardContainer {
    width: 99%;
    height: calc(100% - 8%);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    overflow-y: scroll;
    gap: 0.5rem;
    // border: 1px solid blue;
    margin-left: 0.5rem;
  }
  .cards {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 32%;
    height: auto;
    background: #ffffff;
    border: 1px solid #f2f5fa;
    border-radius: 0.625rem;
    // border: 1px solid blue;
  }
  .cards:hover {
    transform: scale(1.03);
    border-radius: 0.625rem;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  .topitems {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.625rem 10px;
    margin: 5px auto;
  }
  .itemText {
    font-size: 1.2rem;
    font-weight: 500;
  }
  .itemIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    .editIcon {
      // color: white;
      display: flex;
      margin: 0 auto;
      text-align: center;
      padding: 0px 5px;
      color: #26405e;
      text-align: center;
      padding: 0px 3px;
      font-size: 30px;
    }
  }
  .paracontainer {
    display: flex;
    align-self: center;
    height: auto;
    color: "#616B7A";
    padding: 2px 8px;
    p {
      color: #616b7a;
      font-family: "Roboto";
      font-size: normal;
      font-weight: 500;
      font-size: 0.8rem;
      line-height: 1rem;
      padding: 0px 0.625rem;
      /* overflow-y: scroll; */
    }
  }
  p:nth-child(3) {
    margin-left: 2%;
    color: #616b7a;
    font-weight: 700;
    font-size: 0.8rem;
    line-height: 1rem;
    padding: 0.5rem 0.6rem;
  }
}
// iphone-------Responsive-------
@media only screen and (max-width: 415px) {
  .topBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5%;
    width: 100%;
    height: 4rem;
    // border: 1px solid rgb(223, 28, 197);
    h1 {
      text-align: center;
      font-weight: 500;
      margin-left: 1rem;
      font-size: 1.2rem;
    }
  }
  .rightIconContainer {
    // border: 1px solid blue;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 13rem;
    height: 2.5rem;
    margin-right: 1.5%;
    .search-area {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid grey;
      input {
        padding: 0.2rem 0.6rem;
        background: none;
        outline: none;
      }
    }
    .pdf {
      height: 2rem;
      width: 7.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0.625rem auto;
      background-color: #1a3447;
      border-radius: 3.35438px;
      cursor: pointer;
      p {
        color: white;
        font-size: 0.8rem;
      }
      .Icons {
        color: #ffffff;
        text-align: center;
        padding: 0px 5px;
        font-size: 2.2rem;
      }
    }
    .pdf2 {
      height: 2rem;
      width: 5rem;
      display: flex;
      // justify-content: space-between;
      align-items: center;
      margin: 0.625rem auto;
      background-color: #1a3447;
      border-radius: 3.35438px;
      cursor: pointer;
      // border: 1px solid brown;
      p {
        color: white;
        font-size: 0.8rem;
      }
      .Icons {
        color: #ffffff;
        text-align: center;
        padding: 0px 5px;
        font-size: 2rem;
      }
    }
  }
  .inner-container-Announcement {
    height: calc(100% - 5%);

    overflow-y: scroll;
  }
  .CardContainer {
    width: 98%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    overflow-y: scroll;
    gap: 0.2rem;
    // border: 1px solid red;

    // border: 1px solid blue;
  }
  .cards {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    width: 97%;
    height: auto;
    background: #ffffff;
    border: 1px solid #f2f5fa;
    border-radius: 0.625rem;
    margin-top: 0.2%;
    margin-left: 0.2%;
    margin-bottom: 1rem;
  }
  .topitems {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.625rem 1.25rem;
    margin: 0.313rem auto;
  }
  .itemText {
    font-size: 1.2rem;
    font-weight: 500;
  }
  .itemIcon {
    width: 2.125rem;
    color: white;
    background-color: #f2f5fa;
    border-radius: 7px;
    cursor: pointer;
    //  border: 1px solid red;
  }
  .paracontainer {
    display: flex;
    align-self: center;
    /* height: 126px; */
    height: auto;
    color: "#616B7A";
    // border: 1px solid red;
    padding: 0.125rem 0.5rem;
  }
  .para {
    color: #616b7a;
    font-family: "Roboto";
    font-size: normal;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.125rem;
    padding: 0px 0.625rem;
    /* overflow-y: scroll; */
  }
  p:nth-child(3) {
    margin-left: 2%;
    color: #616b7a;
    font-weight: 700;
    font-size: 0.8rem;
    line-height: 1rem;
    padding: 0.5rem 0.6rem;
  }
}
.action-buttons {
  height: 2rem;
  align-items: center;
  display: flex;
  margin: 0rem 1rem;
  gap: 1rem;
  .cancel-btn {
    background-color: rgb(174, 47, 47);
    cursor: pointer;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    border-radius: 5px;
  }
  .save-btn {
    background-color: #38ab7c;
    cursor: pointer;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    border-radius: 5px;
  }
  p {
    color: #fff;
    font-size: 0.8rem;
    padding: 0 1rem;
  }
}

.inner-container-Announcement {
  padding: 1rem;
  .editor-container {
    background: white;
    border-radius: 8px;
    padding: 1rem;
    margin-bottom: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    .Heading_Edit_btn {
      border-radius: 3px;
      border: 0.5px solid rgb(197, 189, 189);
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
    }
    .title-input {
      width: 85%;
      padding: 0.5rem;
      border-radius: 4px;
      font-size: 1rem;
      gap: 1rem;
      outline: none;
    }
    .quill {
      margin-top: 1rem;
      .ql-toolbar {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }
      .ql-container {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        min-height: 200px;
      }
    }
  }
}

.ql-editor p{
    margin: 0.5rem 0;
    font-size: 0.8rem;
}
.ql-editor {
  font-family: "Arial", sans-serif;
  font-size: 0.8rem;
}
.editor-content {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "Arial", sans-serif;
  }
  p {
    font-family: "Arial", sans-serif;
    margin: 0.5rem 0;
  }
}

