.leftContainer {
  display: flex;
  flex-direction: column;
  padding: 0px;
  width: 445px;
  height: 100%;
  border-top: 1px solid grey;

  // border: 1px solid black;
  border-right: 1px solid #c0c0c0;
  .small-toggle .react-toggle-thumb {
    width: 10px; /* Adjust the width as needed */
    height: 10px; /* Adjust the height as needed */
  }

  .small-toggle .react-toggle-track {
    width: 40px; /* Adjust the width as needed */
  }
  .messageDiv {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    padding: 16px 0px;
    width: 100%;
    height: 106px;
    // background: #ffffff;
    // border: 1px solid blue;
    p {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #000000;
      margin-left: 20px;
    }
  }
  img {
    width: 20px;
    margin-left: 10px;
  }

  @media (max-width: 768px) {
    width: 100%;
    border-right: none;
  }
}
.refresh-main-container{
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 111;
  position: relative;
  // top: -13%;
}
.refresh-container {
  // width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: 5px;
  background-color: #ffffff; // White background for the container
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); // Soft shadow

  span {
    margin-right: 8px; // Spacing between text and image
    font-size: 16px;
    font-weight: 500;
    color: #333; // Text color
  }

  img {
    width: 24px; // Adjust size as needed
    height: 24px;
  }
}

.searchInput {
  display: flex;
  flex-direction: row;
  align-items: center;
  // border: 1px solid grey;
  background-color: #f7f8fa;
  align-self: center;
  width: 401px;
  height: 40px;
  margin-top: 5px;

  input {
    width: 100%;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    padding-left: 10px;
    background-color: #f7f8fa;
    height: 100%;
    outline: none;
    background: #ffffff !important;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
}
.searchInput input:focus {
  border: 2px solid #38ab7c;
}

.MessageDiv {
  height: calc(100vh - 100px);
  overflow-y: auto; /* Change scroll to auto */
  overflow-x: hidden;
  padding-right: 15px; /* To avoid content hiding under the scrollbar */
}

.MessageDiv::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.MessageDiv::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

.MessageDiv:hover::-webkit-scrollbar {
  width: 6px;
}

.MessageDiv:hover::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.7);
}

@media (max-width: 768px) {
  .MessageDiv {
    height: calc(100vh - 206px);
  }
}
.MessageNav {
  // border: 1px solid red;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0px 1rem;
  ul {
    // border: 1px solid red;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0px;
    width: 100%;
    height: 42px;
  }

  li {
    display: inline-block;
    padding: 0 15px;
    cursor: pointer;
    color: #717171;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;

    &.active {
      border-bottom: 2px solid #38ab7c;
      color: #38ab7c;
    }
  }
  @media (max-width: 768px) {
    ul {
      width: 100%;
    }
  }
}

.rightContainer {
  width: 100%;
  height: calc(100vh - 100px);
  margin-right: 5px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  border-top: 1px solid grey;
  /* border: 1px solid red; */
  @media (max-width: 768px) {
    height: 70%;
  }
}

.rightContainer::-webkit-scrollbar {
  display: none;
}

.rightContainer::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.maindate {
  width: 100% !important;
  display: flex;
  justify-content: center !important;
  padding: 1% 0 1% 0;
}

.TodayDate {
  // background-color: #e2e2e2;
  color: #7a7474;
  width: auto !important;
  text-align: center;
  border-radius: 6%;
  padding: 0 1% 0 1%;
}

.CustMessage {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  // justify-content: space-around;
  padding: 0px;
  // gap: 23px;
  position: relative;
  width: 100%;
  height: 234px;
  top: 40px;
  left: 10px;

  // border: 1px solid blue;
  // box-shadow: 1px 1px 15px grey;
  .CustomerAvtar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background: #38ab7c;
    border-radius: 38px;
    color: white;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
  }

  .admin {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background: #0000ff;
    border-radius: 38px;
    color: white;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
  }

  .mainDiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // gap: 10px;
  }
  .CustomerMessage {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0px;
    // gap: 25px;
    width: 100%;
    height: 234px;
  }

  .newChatButton {
    display: flex;
    justify-content: end;
    width: 100%;
    position: relative;
    bottom: 23%;
    z-index: 1;
  }

  .TopIcons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0px;
    width: 100%;
    height: 22px;
    // border: 1px solid blue;

    p {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
    }
    h5 {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
    }
  }
  h6 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #616b7a;
  }
  @media (max-width: 768px) {
    top: 0; /* Adjust the position for smaller screens */
    left: 0; /* Adjust the position for smaller screens */
    width: 100%; /* Adjust the width for smaller screens */
  }
}
.AdminMessage {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 23px;
  width: 100%;
  height: 234px;
  margin-top: 10px;
  // border: 1px solid blue;
  .AdminAvtar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background: #0000ff;
    border-radius: 38px;
    color: white;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
  }
  .AdminMessageContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 25px;
    width: 100%;
    height: 234px;
  }
  .AdminIcons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0px;
    width: 100%;
    height: 22px;
    // border: 1px solid blue;
    p {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
    }
    h5 {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      padding-right: 20px;
    }
  }
  h6 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #616b7a;
  }
}
.UserAvatar {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  // border-bottom: 3px solid #98e5bd;
  background-color: #98e5bd;
  color: #616b7a;
  font-family: "Roboto", sans-serif;
  font-size: 1.2rem;
  font-weight: 500;
}
.UserAvtarIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: #38ab7c;
  border-radius: 38px;
  color: white;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  margin-right: 1%;
}
.hrLine {
  border: 1px solid #c0c0c0;
}
.ReplyDiv {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 17px;
  width: 100%;
  height: 150px;
  border-width: 1px 0px;
  border-style: solid;
  .textfield {
    width: 95%;
    height: 150px;
    outline: none;
    border: 1px solid #c0c0c0;
    border-radius: 9px;
    font-size: 15px;
    padding-left: 10px;
    padding-top: 5px;
    resize: vertical;
    overflow-y: auto;
  }
  @media (max-width: 768px) {
    width: 90%;
    left: 5%;
  }
}
.HrDate {
  position: relative;
  top: -0.6rem;
  left: 26rem;
  z-index: 1;
  background-color: #fff;
  width: 6rem;
  @media (max-width: 768px) {
    left: auto;
    right: 0;
  }
}
.btnForReply {
  width: 4rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  border-radius: 0.2rem;
  align-items: center;
  background: #38ab7c;
  font-size: 0.9rem;
  padding: 0.7rem 1.5rem;
  // margin-bottom: 3rem;
  outline: none;
  color: #fff;
  font-family: "Roboto";
  margin-left: 1.5rem;

  @media (max-width: 768px) {
    /* Add any specific styles for smaller screens if necessary */
  }
}
.searchInputInAllUsers {
  display: flex;
  flex-direction: row;
  align-items: center;

  background-color: #f7f8fa;
  align-self: center;
  width: 401px;
  height: 40px;
  margin-top: 5px;
  margin-bottom: 5%;

  input {
    width: 100%;
    font-family: "Roboto";
    border-bottom: 1px solid #38ab7c;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    padding-left: 10px;
    background-color: #f7f8fa;
    height: 100%;
    outline: none;
    background: #ffffff !important;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
}
.searchInputInAllUsers input:focus {
  border-bottom: 2px solid #38ab7c;
}
