// .mainContainer {
//   border: 1px solid red;
//   height: calc(100% - 70px);
//   width: 98%;
// }
.UserProfileMainContainer {
  width: 100%;
  height: calc(100% - 70px);
}

.UserProfileMainContainerHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.4rem 1rem 0rem 1rem;
  align-items: center;
  background-color: #f5f5f5;
  .HeadingAndBack {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    h1 {
      padding-left: 0.2rem;
      color: #26405e;
      font-size: 1.2rem;
      font-weight: 600;
    }
  }

  .Close_btn_And_Edit_btn {
    display: flex;
    justify-content: center;
    align-items: center;

    .cancelBtn {
      margin-left: 0.2rem;
      gap: 0.4rem;
      outline: none;
      background-color: #f5f5f5;
    }

    .saveBtn {
      margin-left: 0.8rem;
      outline: none;
      color: #fff;
      gap: 0.3rem;

      img {
        color: #fff;
        margin-right: 0.2rem;
      }
    }
  }
}

.View_docs {
  display: flex;
  align-items: center;
  background-color: #38ab7c;
  border-radius: 0.5rem;
  gap: 0.5rem;
  padding: 0.5rem 0.5rem;
  cursor: pointer;

  span {
    font-family: "Roboto";
    font-size: 0.8rem;
    color: #fff;
  }
}

.UserProfile_Detail_Container {
  display: flex;
}

.UserDetail_container {
  height: 85vh;
  display: flex;
  gap: 0.5rem;

  .Full_Detail {
    width: calc(100% - 35%);
    gap: 5rem;

    .Personal_Detail {
      height: 28%;
      background-color: #f5f5f5;
      .RoleContainer {
        display: flex;
        align-items: center;
        padding:0.8rem 0rem 0rem 1rem;
        span {
          font-size: 0.8rem;
          font-weight: 600;
          color: #000;
        }
      }
      .UserRole {
        color: #38ab7c;
        font-size: 0.8rem;
        font-weight: 600;
        padding-left: 0.2rem;
      }

      .NameContainer {
        display: flex;
        gap: 0.6rem;
        align-items: center;
      }

      h1 {
        padding: 1rem 0rem 0rem 1rem;
        font-weight: 600;
        font-family: sans-serif;
        text-transform: capitalize;
      }

      .LocationContainer {
        margin-top: 0.5rem;
        display: flex;
        align-items: center;
        text-align: center;
        gap: 0.3rem;

        img {
          width: 12px;
          height: 12px;
        }

        p {
          font-size: 0.65rem;
          text-align: center;
          font-weight: 600;
          color: #1a3447;
        }
      }

      .Rating_Container {
        margin: 0.8rem 0rem 0rem 0.8rem;
        h4 {
          font-size: 0.8rem;
          color: grey;
          font-family: Tahoma, sans-serif;
          font-weight: 600;
          padding-left:0.2rem
        }
      }
    }

    .ChatContainer {
      display: flex;
      gap: 1rem;
      margin: 0.5rem;
      .Chat {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        cursor: pointer;
        border: 1px solid #000;
        border-radius: 10px;
        padding: 0.2rem 0.5rem;
        img {
          height: 20px;
          width: 20px;
        }

        span {
          font-size: 0.65rem;
          text-align: center;
          font-weight: 600;
          color: #1a3447;
        }
      }

      .UnVerified {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        p {
          font-size: 0.65rem;
          text-align: center;
          font-weight: 600;
          color: #1a3447;
        }
      }

      .RateNComment {
        border-radius: 10px;
        background-color: #38ab7c;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        p {
          text-align: center;
          color: #fff;
          font-size: 0.75rem;
          padding: 0.3rem 0.5rem;
        }
      }
    }

    .About_Container {
      height: calc(100% - 29%);
      margin-top: 1%;
      background-color: #f5f5f5;

      .About {
        border-bottom: 2px double grey;
        margin: 1rem;

        h5 {
          padding: 1rem 0rem 0.3rem 0.1rem;
          color: #000;
          font-weight: 500;
          font-size: 1rem;
          font-family: Georgia, "Times New Roman", Times, serif;
        }
      }

      .Header {
        font-size: 0.7rem;
        font-weight: 600;
        color: #5d676f;
        padding: 1rem;
        text-transform: uppercase;
      }

      .Common_Details {
        display: flex;
        align-items: center;
        margin-left: 1rem;
        justify-content: baseline;

        .Common_headers {
          color: #000;
          font-weight: 500;
          font-size: 0.9rem;
          font-family: Georgia, "Times New Roman", Times, serif;
        }

        .Common_Data {
          font-size: 0.7rem;
          text-align: center;
          font-weight: 600;
          color: #1a3447;
          margin-left: 1rem;
        }
      }
    }
  }
}

.UserProfile_Deatils {
  overflow-y: auto;
  width: 28%;
  height: 100%;
  // border: 2px solid rgb(220, 217, 217);
  background-color: #f5f5f5;

  .Avtar_Conatiner {
    margin: 2rem auto;

    .avatar {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 1.2rem;
    }

    img {
      width: 100%;
    }

    .Verified {
      position: relative;
      width: 1.3rem;
      height: 1.3rem;
      right: 1rem;
      top: 1rem;
    }

    h1 {
      text-align: center;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #000000;
      padding-top: 0.5rem;
      padding-right: 1rem;
    }

    h6 {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 22px;
      text-decoration-line: underline;
      color: #0085ff;
      padding-right: 1rem;
    }
  }

  .location_detail {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.2rem 1.5rem;

    h1 {
      font-family: "Roboto";
      font-weight: 500;
      font-size: 1rem;
      color: #000;
    }

    span {
      color: #1a3447;
      font-size: 0.8rem;
      font-weight: 400;
    }
  }

  .Address {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0.5rem 1.5rem;

    .BusinessAddress {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.2rem 0;

      img {
        width: 1.2rem;
      }

      .business_address {
        width: 70%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0.2rem 0;

        h5 {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 500;
          font-size: 0.8rem;
          color: rgba(0, 0, 0, 0.7);
          padding: 0.2rem 1rem;
          margin-left: 1rem;
        }

        h2 {
          padding: 0.2rem 1rem;
          margin-left: 1rem;
          font-family: "Roboto";
          font-style: normal;
          font-weight: 400;
          font-size: 0.8rem;
          color: #000000;
        }
      }
    }

    .IsVerified {
      border-radius: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #38ab7c;
      cursor: pointer;

      span {
        color: #fff;
        font-size: 0.6rem;
        text-align: center;
        padding: 0.2rem 0.5rem;
      }
    }
  }

  .Discription_title {
    color: #26405e;
    font-size: 1rem;
    font-weight: 600;
    padding: 0.5rem 0rem 0.5rem 1.5rem;
  }

  h4 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 0.7rem;
    line-height: 1.1rem;
    color: #616b7a;
    padding: 0.2rem 1.5rem;
  }

  .AddProject {
    background-color: #38ab7c;
    width: 35%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.5rem auto;
    text-align: center;
    border-radius: 1rem;
    cursor: pointer;

    h1 {
      font-size: 0.8rem;
      color: #fff;
      font-family: "roboto";
      padding: 0.4rem 0;
    }
  }
}

.Addresss_container {
  display: flex;
  flex-direction: column;

  .common_address_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.4rem 0;
    width: 86%;
    margin: 0 auto;

    h1 {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 600;
      font-size: 0.8rem;
      line-height: 1rem;
      color: #26405e;
    }

    span {
      font-family: "Roboto";
      font-style: normal;
      font-size: 0.8rem;
      line-height: 1rem;
      color: #26405e;
      margin-right: 0.2rem;
    }

    .Stars {
      margin-left: 0.2rem;
      width: fit-content;
    }
  }
}

.UserProject_Deatils {
  height: 100vh;
  border: 2px solid rgb(220, 217, 217);

  // width: calc(100% - 25%);
  .Chat_container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;

    .CustomerMessage_Container {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      width: 98%;

      .Customer_LastActive {
        margin: 0.5rem 0;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;

        .LastSeen {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          // border: 1px solid red;
        }

        h5 {
          color: #000;
          font-size: 0.7rem;
          text-align: center;
          padding-right: 1rem;
        }

        span {
          font-size: 0.6rem;
          align-items: center;
          color: rgba(0, 0, 0, 0.6);
        }

        img {
          width: 2rem;
          border-radius: 50%;
          margin-left: 0.5rem;
        }
      }

      .CustomerMessage {
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        h3 {
          background-color: #42426e;
          color: #fff;
          border-radius: 0.8rem;
          padding: 0.5rem;
          font-size: 0.6rem;
          font-family: "roboto";
        }
      }
    }

    .AdminReminder {
      width: 36%;
      justify-content: space-between;
      text-align: center;
      display: flex;
      padding: 1.5rem 0;
      // border: 1px solid red;
      align-items: center;
      margin: 0 auto;

      img {
        width: 0.9rem;
      }

      span {
        text-align: center;
        font-size: 0.6rem;
        color: #ffa100;
      }
    }

    .AdminMessage_Container {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      width: 98%;

      .Admin_LastActive {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        h5 {
          margin-left: 0.5rem;
          color: #000;
          font-size: 0.7rem;
          text-align: center;
          padding-right: 1rem;
        }

        span {
          font-size: 0.6rem;
          align-items: center;
          color: rgba(0, 0, 0, 0.6);
        }

        img {
          width: 2rem;
          border-radius: 50%;
        }
      }

      .AdminMessage {
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;

        h3 {
          background-color: #42426e;
          color: #fff;
          border-radius: 0.8rem;
          padding: 0.5rem;
          font-size: 0.6rem;
          font-family: "roboto";
        }
      }
    }
  }
}

.Completed_jobs_detail {
  overflow-y: auto;

  h1 {
    cursor: pointer;
    padding: 1rem;
  }

  .Images_demo_container {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    .Image_demo {
      display: flex;
      flex-direction: column;
      padding: 0.3rem;
      cursor: pointer;

      img {
        width: 14.3rem;
        border-radius: 0.3rem;
      }

      h3 {
        padding: 0.5rem 0.2rem;
        color: #000;
        font-size: 0.8rem;
        font-family: "roboto";
      }
    }
  }
}

.Project_listed_container {
  width: 100%;
  height: calc(100vh - 250px);
  overflow-y: auto;
  .ProjectWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.5rem;
    overflow-y: auto;
  }
}

.Customer_Completed_jobs {
  display: flex;
  flex-direction: column;
  // flex-wrap: wrap;
  h2 {
    color: #000;
    font-size: 0.8rem;
    font-weight: 400;
    font-family: "Roboto";
    padding: 0.9rem 0.3rem;
  }

  .Project_main_container {
    border: 1px dotted #616b7a;
    padding: 0.2rem 0.5rem;
    border-radius: 1rem;
    height: auto;
    overflow: hidden;
    max-height: 200px;
    min-width: 32.5%;
    cursor: pointer;

    .Project_Container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .NameAndStar_Container {
        gap: 0.5rem;
        margin: 0.2rem 1rem;
        h1 {
          font-family: "roboto";
          font-size: 0.9rem;
          display: flex;
          align-items: center;
          color: #000;
          span {
            padding: 0.3rem;
            font-family: "roboto";
            font-size: 0.9rem;
            color: #616b7a;
          }
        }
        .Location_continer {
          margin: 0.2rem 0;
          display: flex;
          align-items: center;
          font-family: "roboto";
          font-size: 0.9rem;
          color: #000;
          .locations {
            padding: 0.3rem;
            font-family: "roboto";
            font-size: 0.7rem;
            color: #616b7a;
          }
        }
        .Stars_for_CompletedJobs {
          display: flex;
          justify-content: space-between;
          padding: 0.2rem;
          border: 1px solid red;
        }

        .SubCAtegoryContainer {
          display: flex;
          align-items: center;
          margin: 0.2rem 0;

          // padding: 0.2rem;
          h5 {
            border: 1px solid rgba(97, 107, 122, 0.4);
            border-radius: 1rem;
            padding: 0.1rem 0.5rem;
            align-items: center;
            font-size: 0.7rem;
            color: #000;
          }
        }
      }

      .fileAndPrice_Container {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: end;
        gap: 1rem;
        margin-right: 0.2rem;
        margin: 0.5rem 1rem;
        .StatusContainer {
          display: flex;
          gap: 1.5rem;
          justify-content: end;
        }
        .Edit_and_Update {
          display: flex;
          align-items: center;
          gap: "0.5rem";
          align-items: center;
          .switch_button {
            margin-top: 0.5rem;
            margin-left: 0.3rem;
          }
        }

        .editIcon {
          cursor: pointer;
          margin-right: "1rem";
        }

        .Files_Icon {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 0.3rem;

          span {
            font-size: 0.7rem;
            color: #42426e;
          }

          img {
            cursor: pointer;
          }
        }

        h5 {
          color: #616b7a;
          font-size: 0.8rem;
          font-family: "Roboto";

          span {
            color: #00309f;
            font-size: 0.9rem;
            font-weight: 500;
          }
        }
      }
    }

    .descriptions {
      font-family: "roboto";
      font-size: 0.8rem;
      display: flex;
      align-items: center;
      color: #000;

      span {
        color: #26405e;
        font-size: 0.9rem;
        font-weight: 400;
        padding-left: 0.2rem;
      }
    }
  }
}

// rate and comment
.RateMainContainer {
  display: flex;
  flex-direction: column;
  width: 25rem;
  height: auto;

  .Cross {
    width: 0.8rem;
    display: flex;
    align-self: flex-end;
    position: relative;
    bottom: 1.5rem;
    right: 1rem;
    cursor: pointer;
  }

  h3 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 19px;
    text-align: center;
    color: #26405e;
    padding: 0.5rem 0;
    border-bottom: 2px solid #26405e;
  }

  .InpputContainer {
    margin: 1rem 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .RatingContainer {
      margin-left: 0.5rem;
      display: flex;
      flex-direction: column;

      span {
        font-size: 0.8rem;
        color: #26405e;
        padding-left: 0.2rem;
        font-weight: 500;
      }
    }

    .Dual_Input {
      width: 98%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 auto;

      input {
        outline: none;
        border: 0.5px solid #26405e;
        margin: 0 auto;
        border-radius: 5px;
        font-size: 0.7rem;
        color: #26405e;
        padding: 0.2rem 0.5rem;
        width: 48%;
      }
    }

    textarea {
      outline: none;
      border: 0.5px solid #26405e;
      width: 95%;
      margin: 0 auto;
      border-radius: 5px;
      font-size: 0.7rem;
      color: #26405e;
      padding: 0.2rem 0.5rem;
    }
  }

  .SubmitBTN {
    width: fit-content;
    background-color: #38ab7c;
    border-radius: 10px;
    padding: 0.2rem 0.5rem;
    display: flex;
    margin: 0 auto;
    color: #fff;
    font-size: 0.8rem;
    font-weight: 500;
    cursor: pointer;
    margin-bottom: 1rem;
  }
}

// Rate and Comment tab
.CommentMainContainer {
  height: calc(100vh - 180px);
  width: 60%;
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  background-color: #fff;
  border-radius: 5px;
  margin-top: 0.5rem;

  .RateAndCommentMainContainer {
    height: 100%;
    overflow-y: auto;
  }

  h3 {
    text-align: center;
    font-size: 1.1rem;
    color: #26405e;
    font-weight: 500;
    font-family: Georgia, Times, "Times New Roman", serif;
    border-bottom: 2px solid #26405e;
    width: fit-content;
    display: flex;
    margin: 0 auto;
    padding: 0.2rem 0;
  }

  .RateAndCommentContainer {
    height: auto;
    border: 1px solid #26405e;
    width: 98%;
    margin: 1rem auto;
    border-radius: 5px;

    .HeaderContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .NameAndrating {
        margin: 1rem 1rem 0.5rem 1rem;
        display: flex;
        gap: 0.5rem;
        align-items: center;
        padding: 0.5rem 0;
        img {
          width: 30px;
          height: 30px;
        }
        p {
          font-size: 0.8rem;
          font-weight: 600;
          font-family: sans-serif;
          color: #26405e;
        }
      }

      .ProjectTitle {
        background-color: #38ab7c;
        border-radius: 10px;
        padding: 0.2rem 0.5rem;
        margin-right: 1rem;
        color: #fff;
        font-size: 0.8rem;
        font-weight: 500;
        font-family: sans-serif;
      }
    }

    .paragraph {
      font-size: 0.8rem;
      font-weight: 500;
      font-family: sans-serif;
      color: #26405e;
      padding: 0.2rem 1rem;
    }
  }
}
.Empty_Text_Container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.Empty_Text {
  text-transform: uppercase;
  text-align: center;
  font-size: 1.5rem;
  color: #26405e;
  font-weight: 600;
  font-family: sans-serif;
}
.CreatedTime {
  font-size: 0.5rem;
  color: #26405e;
  font-family: sans-serif;
  border: 0.5px solid #38ab7c;
  border-radius: 10px;
  padding: 0.2rem 0.5rem;
  margin-top: 0.2rem;
  font-weight: 600;
}
// Project main container
.ProjectMainContainer {
  width: 100%;
  height: calc(100vh - 170px);
  background-color: #fff;
  margin: 0.4rem 0rem;
  overflow-y: auto;
  .ProjectWrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;

    .Project_main_container {
      border: 1px dotted grey;
      width: 32.7%;
      border-radius: 1rem;
      padding: 0.5rem 0;
    }
  }
  .ProjectNameContainer {
    display: flex;
    gap: 0.2rem;
    align-items: center;
    padding: 0.8rem 0.5rem 0rem 0.5rem;
    h2 {
      font-size: 0.8rem;
      color: #000;
      font-family: "roboto";
      font-weight: 500;
    }
    h4 {
      font-size: 0.7rem;
      color: #26405e;
      font-family: sans-serif;
      font-weight: 600;
    }
    .SubCat {
      border: 1px solid orangered;
      display: flex;
      gap: 0.3rem;
      align-items: center;
      border-radius: 1.5rem;
      padding: 2px 0.5rem;
      h3 {
        font-size: 0.7rem;
        color: #000;
        font-family: "roboto";
        font-weight: 500;
      }
    }
    h6 {
      font-size: 0.6rem;
      color: #26405e;
      font-family: sans-serif;
      font-weight: 500;
    }
  }
  .HeaderContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 0.5rem;
    .status {
      text-align: center;
      border-radius: 1.5rem;
      padding: 0.1rem 0.5rem;
      background-color: #38ab7c;
      color: #fff;
      font-family: sans-serif;
      font-size: 0.8rem;
    }
  }
}
.current_tag {
  display: flex;
  justify-content: center;
  align-items: center;
  .Draft_tag {
    border: 1px solid rgba(97, 107, 122, 0.4);
    border-radius: 1rem;
    padding: 0rem 0.5rem;
    font-size: 0.7rem;
    background-color: rgb(192, 167, 118);
    color: #000;
  }

  .Working_tag {
    border: 1px solid rgba(97, 107, 122, 0.4);
    border-radius: 1rem;
    padding: 0rem 0.5rem;
    font-size: 0.7rem;
    background-color: rgb(77, 154, 124);
    color: #fff;
  }

  .Bidding_tag {
    border: 1px solid rgba(97, 107, 122, 0.4);
    border-radius: 1rem;
    padding: 0rem 0.5rem;
    font-size: 0.7rem;
    background-color: rgb(45, 45, 81);
    color: #fff;
  }

  .Completed_tag {
    border: 1px solid rgba(97, 107, 122, 0.4);
    border-radius: 1rem;
    padding: 0.1rem 0.5rem;
    font-size: 0.7rem;
    background-color: rgb(60, 144, 60);
    color: #fff;
  }
}
