.FilterMenuDiv {
  width: 20rem;
  height: 100vh;
  z-index: inherit;
  .topDiv {
    display: flex;
    justify-content: space-between;
    padding: 0rem 1rem;
    margin-top: 1rem;
    h1 {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 1rem;
      line-height: 19px;
      color: #000000;
    }
    img {
      cursor: pointer;
    }
  }
  .selectionDiv {
    margin-top: 2rem;

    .FilterBox {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 0.8rem;
      .CalnederIcon {
        position: relative;
        top: 1.8rem;
        left: 38%;
        cursor: pointer;
        width: 0.8rem;
      }
      h2 {
        width: 98%;
        padding: 0.2rem 1rem;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #000000;
      }
      input {
        border: 1px solid #8c9ca7;
        border-radius: 0.5rem;
        width: 90%;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #8c9ca7;
        padding: 0.7rem 0.5rem;
        outline: none;
      }
      img {
        position: relative;
        top: 1.7rem;
        left: 38%;
        cursor: pointer;
        width: 0.8rem;
        height: 0.8rem;
      }
    }
  }
  .BottomItems {
    position: absolute;
    bottom: 1rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0rem 1rem;
    h1 {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #000000;
      padding: 0.5rem;
      cursor: pointer;
    }
    h2 {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #6550e9;
      cursor: pointer;
    }
  }
  .customDatePicker .ant-picker-dropdown {
    z-index: 2000; /* Or any value higher than the Dialog's z-index */
  }
}
