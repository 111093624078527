.ChangePassBox {
  width: 22rem;
  height: auto;

  .head_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding: 0.5rem 1rem 0rem 1rem;
    h3 {
      font-family: "roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 1.2rem;
      line-height: 68.51%;
      color: #364670;
    }
    span {
      border-bottom: 4px solid #38ab7c;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 0.875rem;
      padding: 0.5 auto;
      color: #0f2d49;
    }
  }
  .inputBox {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 auto;
    gap: 0.5rem;
    width: 90%;
    .emailInputs {
      height: 3rem;
      margin-top: 1.2rem;
      p {
        padding: 0.5rem 0rem;
        font-family: "roboto";
        font-style: normal;
        font-weight: 500;
        font-size: 0.7rem;
        line-height: 68.51%;
        color: #364670;
      }
      input {
        border-radius: 0.5rem;
        width: 100%;
        padding: 0.4rem 0.5rem;
        outline: none;
        font-size: 0.8rem;
        border: 1px solid #364670;
      }
    }
  }
  .buttons {
    display: flex;
    align-self: flex-end;
    gap: 1.5rem;
    margin: 1rem 0;
    .button1 {
      color: #00309f;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 0.9rem;
      line-height: 16px;
      padding: 0.3rem 0.5rem;
      outline: none;
      border: none;
    }
    .button2 {
      background-color: #38ab7c;
      color: white;
      font-family: "Roboto";
      border-radius: 4px;
      font-style: normal;
      font-weight: 400;
      font-size: 0.9rem;
      line-height: 16px;
      padding: 0.5rem 0.9rem;
      outline: none;
      border: none;
    }
  }
}
