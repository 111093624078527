.transaction-history-container {
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: #f5f5f5;
  height: calc(100vh - 70px);
  .transaction-history-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .Top_item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: fit-content;
  }
  h2 {
    color: #26405e;
    font-weight: 500;
    font-family: "Roboto";
    line-height: 22px;
    text-align: center;
    font-size: 20px;
    margin-top: 5px;
    font-size: 1.2rem;
  }
}
.Header_banking {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #1a3447;
}
.table-scroll {
  max-height: calc(100vh - 11rem);
  overflow-y: auto;
}

.transaction-table {
  width: 100%;
  border-collapse: collapse;
  // margin-top: 20px;
  background-color: white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  table-layout: fixed;
}
th {
  font-size: 0.8rem;
}
thead th:first-child {
  text-align: left;
  padding-left: 1rem;
}
.transaction-table th,
.transaction-table td {
  padding: 12px 8px;
  text-align: center;
  border-bottom: 1px solid #ddd;
}
.transaction-table td {
  //font-family: "roboto";
  font-family: "Roboto";
  text-align: justify;
}
.transaction-table th {
  background-color: #008080;
  color: white;
  font-family: "Roboto";
}

.transaction-table td:first-child {
  text-align: left;
  padding-left: 01rem;
}
.transaction-table tr:hover {
  background-color: #c5d9cf;
}
.search-bar {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  gap: 1rem;
}
.filterContainer {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  p {
    text-align: center;
    font-size: 14px;
    font-family: "roboto";
  }
}
.searchContainer {
  .FilterBox {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    margin-right: 0.5rem;

    .Title_month {
      display: flex;
      align-items: center;
      background-color: #999;
      justify-content: center;
      border-radius: 0.2rem;
      cursor: pointer;
    }
    .CalnederIcon {
      cursor: pointer;
      width: 0.8rem;
    }
    h2 {
      padding: 0.2rem 0.5rem;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #000000;
    }
    input {
      border: 1px solid #8c9ca7;
      border-radius: 0.5rem;
      width: 5rem;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #8c9ca7;
      padding: 0.3rem 0.5rem;
      outline: none;
    }
  }
}
.search-bar input[type="text"] {
  padding: 10px;
  flex-grow: 1;
  margin-right: 15px;
}

.search-bar input[type="text"]:focus {
  outline: none;
  border-color: #008080;
}

.search-bar .MuiSvgIcon-root {
  color: #777;
  cursor: pointer;
}
.months {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 16px;
  color: #26405e;
}
