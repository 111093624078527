.HeadingAndSearchSearchAds {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 01rem 0;
  h1 {
    color: #26405e;
    font-weight: 600;
    font-family: "Roboto";
    line-height: 22px;
    text-align: center;
    font-size: 20px;
    margin-top: 5px;
  }
}
.RightBarSearchAds {
  display: flex;
  justify-content: space-between;
  margin-right: 1rem;
  gap: 0.5rem;
}
.returingDataSearch {
  font-weight: 400;
  font-size: 0.8rem;
  font-family: "Roboto";
}
.locationBySearchAds {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #129160;
  border-radius: 5px;
  color: white;
  font-size: 0.7rem;
  border: none;
  outline: none;
  padding: 0.3rem 0.5rem;
}
.filterSearchAds {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 5px;
  padding: 4px 10px;
  color: black;
  font-size: 13px;
  border: none;
  outline: none;
}
.SearchBySearchAds {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #42426e;
  border-radius: 5px;
  padding: 4px 8px;
  color: white;
  font-size: 12px;
  border: none;
  outline: none;
}
.inputDivSearchAds {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  // width: 15rem;
  // height: 1.9rem;
  // border-radius: 5px;
  border-bottom: 1px solid black;
}
.input-fieldSearchAds {
  outline: none;
  width: 100%;
  height: 100%;
  margin-left: 5px;
  font-size: 12px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  background-color: white;
  padding-left: 5px;
}
.HeaderDetailSearchAds {
  //   border: 1px solid red;
  height: 80px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  //   padding-right: 15rem;
}
.userDIvSearchAds {
  width: 220px;
  //   border: 1px solid blue;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 1rem;
  font-weight: bold;
  font-size: 14;
  font-family: "roboto";
}
.userDIvSearchAds p {
  margin-left: 2rem;
  font-family: "Roboto";
}

.MinPrice {
  width: 140px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  //   border: 1px solid blue;
  position: relative;
  right: 3rem;
  font-weight: bold;
}

.Paid {
  width: 55px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  //   border: 1px solid yellow;
  position: relative;
  right: 6rem;
  font-weight: bold;
}
.EmailSearchAds {
  width: 65px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  //   border: 1px solid yellow;
  position: relative;
  right: 7rem;
  font-weight: bold;
}
.PhoneSearchAds {
  display: flex;
  width: 138px;
  justify-content: center;
  align-items: center;
  //   border: 1px solid green;
  position: relative;
  right: 1.3rem;
  font-weight: bold;
  font-size: 14px;
  font-family: "roboto";
}
.AddressSearchAds {
  width: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  //   border: 1px solid green;
  position: relative;
  padding-right: 7rem;
  right: 9rem;
  // font-weight: bold;
  font-size: 14px;
  font-family: "roboto";
}
.addItem {
  width: 99%;
  border: 0.5px dotted rgb(156, 151, 151);
  height: 2.5rem;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  p {
    color: #26405e;
  }
}
