.CountryBox {
  width: 18rem;
  height: 16rem;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  .InputDiv {
    align-self: center;
    border: 1px solid grey;
    border-radius: 0.3rem;
    display: flex;
    justify-content: center;
    gap: 0.7rem;
    margin-top: 0.5rem;
    width: 95%;
    height: 2rem;
    margin-top: 1rem;
    img {
      width: 1.3rem;
    }
    input {
      outline: none;
      width: 80%;
      height: 100%;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #616b7a;
    }
  }
  .ContryContainer {
    width: 18rem;
    height: 13rem;
    display: flex;
    justify-content: flex-start;
    overflow-y: auto;
    padding: 0.5rem 0.5rem 0.5rem 1rem;
    .CountryName {
      // padding-left: 1rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      p {
        padding-left: 0.5rem;
        font-size: 0.8rem;
        color: #969696;
        text-align: center;
      }
    }
  }
}
