.CreateTempAccountMainContainer {
  width: 100%;
  height: calc(100% - 70px);
  .UserProfileMainContainerHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.8rem 1rem;
    .HeadingAndBack {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      h1 {
        padding-left: 0.2rem;
        color: #26405e;
        font-size: 1.2rem;
        font-weight: 600;
      }
    }
    #inputID::placeholder {
      color: #0e0d0d;
      opacity: 1;
    }
    .Close_btn_And_Edit_btn {
      display: flex;
      justify-content: center;
      align-items: center;
      .cancelBtn {
        margin-left: 0.2rem;
        gap: 0.4rem;
        outline: none;
        background-color: #f5f5f5;
      }
      .saveBtn {
        margin-left: 0.8rem;
        outline: none;
        color: #fff;
        gap: 0.3rem;
        img {
          color: #fff;
          margin-right: 0.2rem;
        }
      }
    }
  }
  .BottomCreateTempAccount {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem 0;
    overflow: auto;
    height: calc(100% - 86px);
    width: 60%;
    background-color: #fff;
    margin: 0 auto;
    border-radius: 0.5rem;
    box-shadow: 1px 1px 5px solid grey;
    .AddCustomerDetails_Container {
      display: flex;
      flex-direction: column;
      width: 95%;
      height: 100%;

      .upload-photo-view {
        display: flex;
        align-items: center;
        height: 8rem;
        margin: 1rem;
        .ImageContainer {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 5rem;
          height: 5rem;
          border-radius: 50%;
          justify-content: center;
          align-items: center;
        }
        img {
          // background-color: red;
          width: 100%;
        }
        .BTN_And_Condition {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          height: 70%;
          p {
            color: #92929d;
            font-size: 0.8rem;
            font-family: "roboto";
            font-weight: 500;
            padding-left: 0.5rem;
          }
          .UploadButtonContainer {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 1rem 0rem 0rem 0.5rem;
            .upload-pic-btn {
              outline: none;
              padding: 0.4rem 0.4rem;
              background-color: #38ab7c;
              color: #fff;
              border-radius: 0.3rem;
              font-size: 0.7rem;

              input {
                font-size: 0.8rem;
                cursor: pointer;
                padding: 0.2rem;
              }
              // span {
              //   position: relative;
              //   right: 7rem;
              // }
            }
            .reset-btn {
              outline: none;
              color: #92929d;
              background-color: #fff;
              border: 1px solid #92929d;
              border-radius: 0.3rem;
              padding: 0.3rem 0.8rem;
              font-size: 0.7rem;
              margin-left: 0.8rem;
            }
          }
        }
      }

      .Common_details {
        display: flex;
        justify-content: space-between;
        padding: 0.5rem;
        margin: 0.5rem;
        .Inputs {
          width: 48%;
          color: #000;
          font-weight: 500;
          font-size: 0.8rem;
          //   height: 2.5rem;
        }
        .InputsUserId {
          width: 100%;
          color: #000;
          font-weight: 500;
          font-size: 0.8rem;
          //   height: 2.5rem;
        }
        .TextArea {
          width: 100%;
        }
      }
      .Status {
        display: flex;
        padding: 1rem 1.2rem;
        align-items: center;

        p {
          color: #1a3447;
          font-size: 0.9rem;
          font-weight: 600;
        }
        .SwitchBTN {
          margin-left: 0.5rem;
        }
      }
    }
  }
}
