.each-slide-effect {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0.5rem 0.1rem;
  img {
    // height: 32rem;
    width: 50%;
    height: auto;
  }
  p {
    color: #26405e;
    font-weight: 400;
    font-size: 1.2rem;
    font-family: "Roboto";
    text-align: center;
    border-bottom: 2px solid #26405e;
    padding: 1rem 0;
  }
}
.Docs_image_container {
  height: auto;
  width: 80%;
  height: 55%;
  margin: 0.5rem auto;
}

.userId {
  text-align: center;
  margin: 0.2rem auto;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  padding: 0.5 auto;
  color: #0f2d49;
  border-bottom: 4px solid #38ab7c;
}

// Slider styling 0------
.image-slider {
  height: 80%;
  margin: 2rem;
}
.Slider_Container {
  height: 25rem;
  display: "flex";
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  img {
    height: 80%;
    width: auto;
    margin: 1rem auto 0rem auto;
  }
  p {
    height: 10%;
    text-align: center;
  }
}
.common_button_image {
  background-color: #38ab7c;
  cursor: pointer;
  width: 20%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;

  p {
    padding: 0.3rem 2rem;
    font-size: 1rem;
    font-family: "roboto";
    font-weight: 400;
    color: #fff;
  }
}
