.sidebar {
  //   padding: 1%;
  // width: $sidebarWidth;
  // @media screen and (max-width: 850px) {
  //   width: $sidebarWidthHidden;
  // }

  .upper-sidebar {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2% 1%;
  }

  .lower-sidebar {
    .sidebar-option-text {
      font-size: 0.8rem;
    }
    .sidebar-option:hover {
      background-color: rgba($color: #444, $alpha: 0.5);
    }
  }
}
@media print {
  .sidebar {
    display: none;
  }
}
