.Cat_Main-container {
  height: calc(100% - 80px);
}
.Tob_Bar_Cat {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.7rem;

  margin: 0 auto;
  h1 {
    color: #26405e;
    font-weight: 500;
    font-family: "Roboto";
    line-height: 22px;
    text-align: center;
    font-size: 20px;
    margin-top: 5px;
    padding-left: 0.5rem;
  }
}
.Cat_Bottom_container {
  height: calc(100% - 50px);
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // border: 1px solid red;
}
.heading {
  // font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 1.3rem;
  line-height: 22px;
}
.publish-change-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  width: 8.5rem;
  height: 2.2rem;
  background: #1a3447;
  border-radius: 3.35438px;
  cursor: pointer;

  &__text {
    font-family: "roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 0.85rem;
    line-height: 110%;
    color: #ffffff;
  }
}

.category-box {
  width: 24%;
  height: 100%;
  background: #fff;

  .Cat_Items_data {
    height: 90%;
    width: 100%;
    overflow-y: auto;

    .TextAndNumber {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 60%;
      // border: 1px solid red;
    }
  }
  h1 {
    font-size: 1rem;
    font-weight: 500;
    font-family: "Roboto";
    padding-top: 01rem;
    padding-bottom: 1.5rem;
    padding-left: 0.5rem;
    // color: #616b7a;
  }
  .category-name-area {
    padding: 0.4rem 0.75rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.2rem;
    &:hover {
      background-color: rgba($color: #aaa, $alpha: 0.5);
      cursor: pointer;
    }
  }
  &__heading {
    height: 10%;
  }
  .category-name {
    font-family: "Roboto";
    font-weight: 500;
    font-size: 0.85rem;
    line-height: 0.95rem;
    color: #000000;
    width: 80% !important;
    flex: 1;
    overflow-wrap: break-word;
    // min-width: 50%;
  }
}

.subcategory-box {
  height: 100%;
  background-color: #fff;
  width: 75%;
  padding: 0.3rem 0.7rem;
}
.TopconTainer {
  width: 100%;
  height: 3rem;
  // border: 1px solid rgb(56, 197, 68);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .subcategory-box__heading {
    display: flex;
    align-items: center;
  }
  .searchBar {
    // border: 1px solid rgb(12, 103, 177);
    height: 100%;
    width: 40%;
    display: flex;
    align-items: center;
    height: 2rem;
    margin-right: 1rem;
    padding: 0 0.25rem;
    border: 0px solid white;
    background-color: #f7f8fa;
  }
  input {
    outline: none;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 0.8rem;
    line-height: 16px;
    color: #5e6278;
    background-color: #f7f8fa !important;
    padding-left: 0.3rem;
  }
}
.subcategory-box {
  &__heading {
    width: 100%;
    .category-name {
      font-family: "Roboto";
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #000000;
    }
  }

  .BottomSubContainer {
    // border: 1px solid red;
    width: 100%;
    height: 90%;
    overflow-y: auto;
    padding-right: 1rem;
    margin-top: 0.5rem;
  }
  .sub-category-text {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #616b7a;
  }
  .subCatIndex {
    // border: 1px solid red;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;

    .bottomButtons {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }

  .sub-category-edit {
    color: #616b7a;
    margin-right: 0.75rem;
  }

  .sub-category-del {
    color: #ff3232;
  }
}

.sub-category-text:focus {
  border: none !important;
}

.NumberOfSubCategories {
  background-color: #26405e;

  /* icon color */
  border-radius: 2px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 68.51%;
  /* or 8px */

  /* WHTIE */
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.4rem;
  padding: 0.4rem;
}

.roundPlus {
  /* Auto layout */

  padding: 7px;
  gap: 10px;

  width: 34.13px;
  height: 34.13px;

  /* Background */

  background: #f2f5fa;
  border-radius: 7px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}
//ipad---
@media only screen and (max-width: 1200px) {
  .category-box {
    width: 40%;
    h1 {
      font-size: 1.5rem;
      font-weight: 600;
      font-family: "Roboto";
    }
  }
  .category-name-area {
    display: flex;
    justify-content: space-between;
    .TextAndNumber {
      // border: 1px solid blue;
      width: 62%;
    }
    .RightButtons {
      margin-left: 1rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 32%;
      .roundPlus {
        width: 30px;
        height: 30px;
        img {
          width: 15px;
          height: 15px;
        }
      }
    }
  }
  .subcategory-box {
    width: 59%;
    .TopconTainer {
      width: 100%;
      height: 3rem;
      // border: 1px solid rgb(56, 197, 68);
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      .searchBar {
        // border: 1px solid rgb(12, 103, 177);
        height: 100%;
        height: 2rem;
        margin-right: 1rem;
      }
      input {
        outline: none;
      }
    }
  }
  .subCatIndex {
    // border: 1px solid red;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }
}
//---mobiles--------------------

@media only screen and (max-width: 415px) {
  .heading {
    // border: 1px solid red;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 22px;
  }
  .publish-change-btn {
    // border: 1px solid red;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8.80524px 13.4175px;
    gap: 6.71px;
    width: 10rem;
    height: 2.2rem;
    /* Dark green */

    background: #1a3447;
    border-radius: 3.35438px;

    &__text {
      font-family: "roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 0.78rem;
      line-height: 110%;
      /* identical to box height, or 15px */

      /* White */

      color: #ffffff;
    }
  }

  .category-box {
    width: 60%;
    // border: 1px solid red;

    h1 {
      font-size: 1.3rem;
      // border: 1px solid red;

      font-weight: 500;
      font-family: "Roboto";
      padding-top: 01rem;
      padding-bottom: 1.5rem;
      padding-left: 0.5rem;
    }
    .category-name-area {
      // border: 1px solid red;
      height: auto;
      display: flex;
      justify-content: space-between;
      width: 100%;
      .TextAndNumber {
        // border: 1px solid blue;
        span {
          font-size: 0.8rem;
        }
      }
      .RightButtons {
        // border: 1px solid rgb(209, 15, 177);
        // margin-left: 1rem;
        width: 20%;
        height: 5rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        .roundPlus {
          width: 25px;
          height: 25px;
          img {
            width: 12px;
            height: 12px;
          }
        }
        .roundPlus2 {
          margin-right: 0.4rem;
        }
      }
    }
    &__heading {
      height: 10%;
    }
    .category-name {
      font-family: "Roboto";
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #000000;
      max-width: 80% !important;
      flex: 1;
      overflow-wrap: break-word;
      min-width: 50%;
    }
  }
  .subcategory-box {
    border-left: 1px solid grey;
    height: 100%;
    background-color: #fff;
    width: 50%;
    padding: 0.3rem 0.7rem;
  }
  .TopconTainer {
    width: 100%;
    height: 3rem;
    // border: 1px solid rgb(56, 197, 68);
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    .subcategory-box__heading {
      display: flex;
      width: 60%;
      align-items: center;
      // border: 1px solid red;
      .category-name {
        font-size: 8px;
      }
      .NumberOfSubCategories {
        font-size: 10px;
        margin-right: 1rem;
      }
    }
    .searchBar {
      // border: 1px solid rgb(161, 226, 8);
      height: 4rem;
      width: 39%;
      display: flex;
      align-items: center;
      height: 2rem;
      margin-right: 1rem;
      padding: 0 0.25rem;
      border: 0px solid white;
      background-color: #f7f8fa;
      img {
        width: 10px;
      }
    }
    .search-input {
      outline: none;
      width: 3rem;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 8px;
      line-height: 16px;
      color: #5e6278;
      background-color: #f7f8fa !important;
      // border: 1px solid red;
      padding-right: 5px;
    }
  }
  .subcategory-box {
    &__heading {
      width: 100%;
      .category-name {
        font-family: "Roboto";
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #000000;
      }
    }
    .BottomSubContainer {
      // border: 1px solid red;
      margin-top: 0.5rem;
    }
    .subCatIndex {
      // border: 1px solid red;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;

      .bottomButtons {
        display: flex;
        justify-content: space-around;
        align-items: center;
        // border: 1px solid red;
        margin: 0.8rem 0rem;
      }
    }

    .sub-category-text {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      // border: 1px solid red;
      color: #616b7a;
    }

    .sub-category-edit {
      color: #616b7a;
      margin-right: 0.75rem;
      // border: 1px solid red;
    }

    .sub-category-del {
      color: #ff3232;
    }
  }

  .sub-category-text:focus {
    border: none !important;
  }

  .NumberOfSubCategories {
    background-color: #26405e;

    /* icon color */
    border-radius: 2px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 68.51%;
    /* or 8px */

    /* WHTIE */
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0.4rem;
    padding: 0.4rem;
  }

  .roundPlus {
    /* Auto layout */

    padding: 7px;
    gap: 10px;

    width: 34.13px;
    height: 34.13px;

    /* Background */

    background: #f2f5fa;
    border-radius: 7px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .subCatIndex {
    // border: 1px solid red;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    .bottomButtons {
      // border: 1px solid red;
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 0.5rem;
      padding: 0px 4px;
      border-bottom: 1px dotted grey;
    }
  }
}
