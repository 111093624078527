.inner-containerForTempBusiness {
  height: calc(100% - 140px);
}
.FirstClildTempBusiness {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-left: 0.8rem;
  gap: 0.2rem;
}
.underLineTextTempBusiness {
  width: 100%;
  color: blue;
  font-weight: 400;
  font-size: 0.8rem;
  font-family: "Roboto";
  cursor: pointer;
  border-bottom: 2px solid blue;
}
.returingDataTempBusiness {
  font-weight: 400;
  font-size: 0.8rem;
  font-family: "Roboto";
  color: #616b7a;
}
.returingDataDate {
  font-weight: 400;
  font-size: 0.7rem;
  font-family: "Roboto";
  color: #616b7a;
}
.ApplyBTNBusiness {
  width: 2rem;
  height: 2rem;
  margin-left: 0.8rem;
  img {
    width: 2rem;
    height: 2rem;
    padding: 0.2rem 0;
  }
}
.changePasswordBTNBusiness {
  cursor: pointer;
  width: 2rem;
  height: 2rem;
  border-bottom: 2px solid blue;
  box-shadow: 1px 1px solid rgb(91, 91, 215);
  margin-left: 0.8rem;

  img {
    width: 2rem;
    height: 2rem;
    padding: 0.2rem 0;
  }
}
.hide_show {
  width: 25px;
  height: 25px;
  outline: none;
  border: none;
  cursor: pointer;
  margin-left: 0.5rem;
}
.passWordContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-self: center;
  height: 1.5rem;
  font-size: 0.85rem;
  font-weight: 500;
  color: #343a7f;
  width: 95%;
  // margin-left: 0.8rem;
  .WrittenPassword {
    letter-spacing: 0.1rem;
    font-size: 1rem;
    font-weight: 500;
    color: #343a7f;
    margin-left: 0.3rem;
    cursor: pointer;
  }
  .WrittenPassword_star {
    letter-spacing: 0.2rem;
    font-size: 1rem;
    font-weight: 500;
    color: #343a7f;
    cursor: pointer;
    width: 2.58rem;
  }
  .Passwords {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: "Roboto";
    padding: 0.3rem 0;
    font-size: 0.7rem;
    font-weight: 500;
    color: #081383;
    margin-left: 0.2rem;
    .noneStar {
      font-size: 0.85rem;
      font-weight: 500;
      color: #343a7f;
      letter-spacing: 0.3rem;
    }
  }
  .eyeButton {
    outline: none;
    border: none;
    margin-right: 1rem;
    margin-bottom: 0.2rem;
  }
  .Eye {
    width: 100%;
    outline: none;
    border: none;
  }
}

.HeadingAndSearchTemp {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0px;
  width: 100%;
  h1 {
    color: #26405e;
    font-weight: 600;
    font-family: "Roboto";
    line-height: 22px;
    text-align: center;
    font-size: 20px;
    margin-top: 5px;
  }
}
.Create-icons p {
  color: #fff;
  font-size: 0.95rem;
}
.table-areaForTempBussiness {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 0.4rem;
  .HeaderDetailTemp {
    font-size: 0.95rem;
    font-weight: 500;
    font-family: "Roboto";
  }
}
.RightBarTemp {
  display: flex;
  justify-content: space-between;
  width: 18rem;
  height: 35px;
}
.locationByTemp {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #129160;
  border-radius: 5px;
  padding: 4px 8px;
  color: white;
  font-size: 12px;
  border: none;
  outline: none;
}
.SearchByTemp {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #42426e;
  border-radius: 5px;
  padding: 4px 8px;
  color: white;
  font-size: 12px;
  border: none;
  outline: none;
}
.inputDivTemp {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 15rem;
  height: 1.9rem;
  // border-radius: 5px;
  border-bottom: 1px solid black;
}
.input-fieldTemp {
  outline: none;
  width: 100%;
  height: 100%;
  margin-left: 5px;
  font-size: 12px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  background: none;
  padding-left: 5px;
}
.HeaderDetailTemp {
  // border: 1px solid red;
  height: 80px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 2rem;
}
.UserDetails {
  // flex justify-start items-center
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .UserName {
    cursor: pointer;
    border: none;
    font-family: "Roboto";
    color: #0084ff;
    font-size: 0.8rem;
    margin-left: 0.4rem;
  }
}
.userDIvTemp {
  width: 210px;
  // border: 1px solid blue;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.userDIvTemp p {
  margin-left: 15px;
  font-family: "Roboto";
}

.CatDivTemp {
  width: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // border: 1px solid blue;
  position: relative;
  right: 15.5px;
}

.SubCatDivTemp {
  width: 125px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // border: 1px solid yellow;
  position: relative;
  right: 2rem;
}
.LocationDivTemp {
  width: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // border: 1px solid yellow;
  position: relative;
  right: 2rem;
}
.AddressDivTemp {
  display: flex;
  width: 90px;
  justify-content: center;
  align-items: center;
  // border: 1px solid green;
}
.EmailAddressTemp {
  width: 140px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  // border: 1px solid green;
}
.StatusAddressTemp {
  width: 80px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  // border: 1px solid green;
}
.ActionTemp {
  width: 90px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  // border: 1px solid green;
}
.change_pass_btns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0.5rem 0;
}
