.EditUserMainContainer {
  height: calc(100% - 80px);
  padding: 0.8rem;
  .TopContainerEditUser {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .TopContainerEditUserButtons {
      display: flex;
      justify-content: center;
      align-items: center;
      button {
        margin-right: 0.2rem;
        img {
          margin-top: 0.2rem;
          width: 1.5rem;
        }
      }
      h1 {
        font-size: 1.2rem;
        font-weight: 600;
        color: #000000;
      }
    }
  }
  .Common_details_Edit {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem;
    margin: 0.5rem;
    .Inputs {
      width: 48%;
      color: #000;
      font-weight: 500;
      font-size: 0.8rem;
      //   height: 2.5rem;
    }
    input {
      outline: none;
      border: 1px solid grey;
      font-size: 0.8rem;
      padding: 0.5rem;
      width: 48%;
      border-radius: 0.5rem;
    }
    .InputsUserId {
      width: 100%;
      color: #000;
      font-weight: 500;
      font-size: 0.8rem;
      //   height: 2.5rem;
    }
    .TextArea {
      width: 100%;
    }
  }
  .ImageDialogContainerEdit {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    margin: 1rem auto;
    .CommonIdContainerEdit {
      border: 1px solid rgb(202, 172, 172);
      border-radius: 0.5rem;
      width: 28%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      padding: 1rem 0.5rem;
      .common_button {
        background-color: #38ab7c;
        padding: 0.3rem 2rem;
        font-size: 0.8rem;
        border-radius: 15px;
        font-family: "roboto";
        font-weight: 400;
        color: #fff;
        cursor: pointer;
        text-align: center;
      }
    }
    .UploadButtonContainer_Edit_User {
      border: 1px solid grey;
      border-radius: 0.2rem;
      padding: 0.2rem;
      margin: 1rem 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      // cursor: pointer;
      input[type="file"] {
        display: block;
        font-style: oblique;
        margin: 0 auto;
      }
      input[type="file"]::file-selector-button {
        display: none;
      }
    }
    .ImageContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 3.4rem;
      height: 4rem;
      border-radius: 50%;
      justify-content: center;
      align-items: center;
      margin: 0.5rem 0;
      img {
        width: 100%;
        margin: 0.5rem 0;
      }
    }
  }
  .RightButtons_EditUser {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .cancelBtn_EditUser {
      margin-right: 0.3rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.3rem 0.5rem;
      border-radius: 0.3rem;
      text-align: center;
      background-color: #f5f5f5;
      color: #0f2d49;
      img {
        color: #0f2d49;
        padding-right: 0.5rem;
      }
    }
    .saveBtn_EditUser {
      margin-left: 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #38ab7c;
      padding: 0.3rem 0.5rem;
      border-radius: 0.3rem;
      color: #fff;
      img {
        color: #fff;
        padding-right: 0.3rem;
      }
    }
  }
  .BottomContainerEditUser {
    display: flex;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    .EditUserDetailContainer {
      width: 60%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      background-color: #fff;
      border-radius: 0.2rem;
      height: 82vh;
      margin: 2rem 0;
      overflow-y: auto;
      .upload-photo-view_edit_User {
        display: flex;
        align-items: center;
        padding-left: 01rem;
        margin: 1rem 0;
        img {
          width: 6rem;
          height: 6rem;
          border-radius: 50%;
          border: 1px solid grey;
        }
        .BTN_And_Condition_Edit {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          p {
            color: #92929d;
            font-size: 0.8rem;
            font-family: "roboto";
            font-weight: 500;
          }
          .UploadButtonContainer_Edit_User {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 0.5rem;
            margin: 1.2rem 0;
            .upload-pic-btn {
              padding: 0.2rem 0.4rem;
              background-color: #38ab7c;
              color: #fff;
              border-radius: 0.3rem;
              font-size: 0.7rem;
            }
            .reset-btn {
              color: #92929d;
              background-color: #fff;
              border: 1px solid #92929d;
              border-radius: 0.3rem;
              padding: 0.3rem 0.8rem;
              font-size: 0.7rem;
              margin-left: 0.8rem;
            }
          }
        }
      }
      .UserId_and_Location_Edit {
        display: flex;
        justify-content: space-between;
        padding: 1rem;
        align-items: center;
        .textfield {
          width: 48%;
          border-color: #38ab7c;
          color: #38ab7c;
          margin: 0.8rem 0;
          cursor: no-drop;
        }
        .EditRatingContainer {
          display: flex;
          flex-direction: column;
          position: relative;
          right: 28%;
          span {
            font-size: 0.8rem;
            border-radius: 15px;
            font-family: "roboto";
          }
        }
        .textfieldValid {
          width: 48%;
          border-color: #38ab7c;
          color: #38ab7c;
          margin: 0.8rem 0;
        }
      }
      .MultiLine {
        width: 96%;
        display: flex;
        margin: 0 auto;
      }
      .Review_Star {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 48%;
        p {
          padding-right: 0.5rem;
          color: #000000;
          font-size: 0.9rem;
          font-family: "roboto";
        }
      }
      .Email_and_SubCategory_Edit_User {
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 1rem;
        margin: 0.2rem;
        width: 100%;
        .textfield {
          width: 34%;
          border-color: #38ab7c;
          color: #38ab7c;
        }
      }
      .Videos_files {
        color: #000000;
        font-size: 0.7rem;
        padding: 1rem;
        font-weight: 500;
      }
      .VideosContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        padding: 0.5rem;
        .Videos {
          background-color: rgba(183, 218, 251, 0.2);
          display: flex;
          flex-direction: column;
          align-items: center;
          border-radius: 0.2rem;
          height: 100%;
          margin-left: 1rem;
          .UploadImg {
            margin-top: 3rem;
          }
          h5 {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 500;
            font-size: 0.7rem;
            line-height: 0.9rem;
            text-align: center;
            letter-spacing: 0.004em;
            color: #26405e;
            padding: 0.6rem 0.5rem;
          }
          p {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 600;
            font-size: 0.7rem;
            line-height: 0.9rem;
            text-align: center;
            letter-spacing: 0.004em;
            color: #616b7a;
            padding: 0.4rem 0.3rem;
          }
          .Button {
            background-color: #ff7d00;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: auto;
            margin: 1rem;
            padding: 0.5rem 1rem;
            border-radius: 0.2rem;
            cursor: pointer;
            img {
              height: 1rem;
              margin-right: 0.2rem;
            }
            span {
              font-size: 0.7rem;
              // padding: 0.5rem 1rem;
            }
          }
          .AddVideo_Icon {
            cursor: pointer;
          }
        }
      }
    }
  }
  .Title_image {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 0.7rem;
    line-height: 0.9rem;
    text-align: center;
    letter-spacing: 0.004em;
    color: #616b7a;
    padding: 0.4rem 0.3rem;
  }
}
