.FilterContainer {
  height: 12rem;
  width: 18rem;
  .topDiv {
    display: flex;
    // border: 1px solid red;
    justify-content: space-between;
    // margin-top: rem;
    padding: 1.3rem;
    h1 {
      font-family: "roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 1rem;
      line-height: 17px;
      color: #000000;
    }
    img {
      width: 0.7rem;
      cursor: pointer;
    }
  }
  .FilterTextsContainer {
    // border: 1px solid red;
    display: flex;
    flex-direction: column;
    padding-left: 1rem;
    .FilterByLocation {
      display: flex;
      //   border: 1px solid blue;
      padding: 0.7rem 0.5rem;
      p {
        padding-left: 1rem;
        font-family: "roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #000000;
        cursor: pointer;
      }
      img {
        width: 1rem;
        cursor: pointer;
      }
    }
  }
}
