.NewAccountMainContainer {
  display: flex;
  flex-direction: row;
  height: calc(100% - 7.5rem);
  overflow-x: auto; 
  overflow-y: hidden; 
  .inner_containerForNewCostmer {
    height: 100%;
    // overflow-y: auto;
    // flex-direction: column;
    width: 150%; 
    align-items: center;
    padding: 0 0.5rem;
    white-space: nowrap; 
  }
  .inner_containerForNewCostmer_withMessage {
    height: 100%;
    width: calc(100% - 22rem);
    align-items: center;
    padding: 0 0.5rem;
  }
  .table-areaForNewCustomer {
    width: 100%;
    max-height: calc(100vh - 4.5rem);
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }
  .table-areaForNewCustomer_with_Sidebar {
    max-height: calc(100vh - 4.5rem);
    display: flex;
    flex-direction: column;
    overflow-x: auto;
    overflow-y: auto;
  }
  .underLineTextVerify {
    width: fit-content;
    color: blue;
    font-size: 0.8rem;
    font-family: "Roboto";
    border-bottom: 2px solid blue;
  }
  .FirstClild {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 0.8rem;
    gap: 0.2rem;
  }
  .returingDataVerify {
    font-weight: 400;
    font-family: "Roboto";
    font-size: 0.7rem;
  }

  .NoAdminDataForNewCustomerAccountVerification {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-top: 2rem;
    h1 {
      padding: 10px;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 600;
      font-size: 1.3rem;
      line-height: 21px;
      letter-spacing: 0.004em;
      text-transform: uppercase;
      color: #000000;
    }
    h3 {
      padding: 5px 10px;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 0.9;
      line-height: 18px;
      letter-spacing: 0.004em;
      color: #797979;
    }
  }
}
.returingData {
  font-weight: 400;
  font-size: 0.8rem;
  font-family: "Roboto";
}
.HeadingAndSearchNewCustomer {
  display: flex;
  justify-content: space-between;
  padding: 5px 0px;
  align-items: center;
  .Left_Details {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  .Header_Common {
    display: flex;
    gap: 0.3rem;
    align-items: center;
    width: fit-content;
    p {
      font-size: 0.8rem;
      font-family: "roboto";
    }
  }
  h1 {
    color: #26405e;
    font-weight: 600;
    font-size: 20px;
    font-family: "roboto";
    line-height: 22px;
    padding-left: 15;
    text-align: center;
  }
}

.Icon-containerNewCustomer {
  display: flex;
  height: 2.2rem;
  padding: 0px 10px;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
}
.iconsNewCustomer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 6rem;
  height: 1.9rem;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
  margin: 0px 3px;
}
.Input-and-iconsNewCustomer {
  display: flex;
  align-items: center;
  width: 100%;
  height: 1.9rem;
  cursor: pointer;
  border-bottom: 1.5px solid #8394a4;
  .searchIconNewCustomer {
    color: #8394a4;
    font-size: 1.3rem;
    margin-top: 0.7rem;
    margin-right: 0.5em;
  }
}

.Input-fieldNewCustomer {
  background-color: transparent;
  // width: 4rem;
  outline: none;
  font-size: 14px;
  margin-top: 5px;
  margin-right: 40px;
}
.Create-iconsNewCustomer {
  background-color: #060649;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5rem;
  height: 1.9rem;
  border-radius: 5px;
  padding: 0px 5px;
  cursor: pointer;
  margin-right: 10px;
  p {
    color: #fff;
    font-size: 0.8em;
    font-weight: 500;
  }
}

.AddIcon-styleNewCustomer {
  color: white;
  border: 1px solid #d4cfcf;
  border-radius: 50%;
  margin-right: 10px;
  color: #8394a4;
  width: 1rem;
  align-self: center;
}

.inputDivNewCustomer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 15rem;
  height: 1.9rem;
  // border-radius: 5px;
  border-bottom: 1px solid black;
}
.input-fieldNewCustomer {
  outline: none;
  width: 100%;
  height: 100%;
  margin-left: 5px;
  font-size: 12px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  background-color: white;
  padding-left: 5px;
}

.MessageSidebar {
  width: 22rem;
  height: calc(100% - 100px);
  background-color: rgb(253, 247, 247);
  z-index: 9999;
  .MessageHeading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.3rem;
    border-bottom: 2px solid #0c064d;
    p {
      color: #0c064d;
      font-size: 1.1rem;
    }
  }
}
.MessageSidebarNone {
  display: none;
  width: 22rem;
  height: calc(100% - 70px);
  background-color: rgb(253, 247, 247);
  padding: 0;
  margin: 0;
}
.BottomContainer {
  display: flex;
  flex-direction: column;
}

.inner-container-Account {
  display: none;
  background-color: #e8e8e8;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.rightContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 10px;
}
.Icon-container {
  width: 18rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 2rem;
  padding: 0px 10px;
}
.icons {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 6rem;
  height: 1.9rem;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
  margin: 0px 3px;
}
.Input-and-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10rem;
  height: 1.9rem;
  cursor: pointer;
  border-bottom: 1.5px solid #070647;
}
.Input-field {
  background-color: transparent;
  width: 5rem;
  margin-left: 5px;
  outline: none;
  font-size: 12px;
  margin-top: 5px;
}
.Create-icons {
  background-color: #060649;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 6rem;
  height: 1.9rem;
  border-radius: 5px;
  padding: 0px 10px;
  cursor: pointer;
  margin-left: 10px;
}
.myControlClassName {
  padding: 38px;
  border: 1px solid red;
}
.myMenuClassName {
  position: relative;
  border: 2px solid red;
  padding: 38px 52px 8px 10px;
}
.AddIcon-style {
  color: white;
  border: 1px solid #d4cfcf;
  border-radius: 50%;
  margin-right: 5px;
}
// .table-areaForNewCustomer {
//   overflow-y: auto;
// }
// .dropdown {
//   width: 60%;
// }
.dropdown-up .Dropdown-control {
  border-radius: 4px;
}

.dropdown-up .Dropdown-menu {
  top: -20%;
  left: -30%;
}
.dropdownDisabled {
  pointer-events: none;
  opacity: 0.5;
  position: relative;
}

@media (max-width: 768px) {
  /* Adjust the width for screens with a max-width of 768px */
  .dropdown {
    width: 50%; /* You can adjust this as needed */
  }
}
.messageIcon {
  color: #444;
  margin-right: 30px;
}
.messageIconNone {
  display: none;
}
.dropdown-toggle {
  border: 1px solid #d4cfcf;
  width: 3rem;
  height: 2rem;
}
.MessageSidebar {
  width: 22rem;
  height: 98%;
  background-color: rgb(253, 247, 247);
  padding: 0;
  margin: 0;
  border: 1px solid rgb(242, 237, 237);
  border-radius: 5px;
}
.MessageSidebarNone {
  display: none;
  width: 22rem;
  height: 100%;
  background-color: rgb(253, 247, 247);
  padding: 0;
  margin: 0;
}

.BottomContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  height: calc(100% - 120px);
  border: 1px solid rgb(242, 237, 237);
  border-radius: 5px;
}
.messageContainer {
  width: 90%;
  margin-top: 30px;
}
.Add_more_text_container {
  width: 95%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1rem;
  .Button-Message {
    background-color: #129160;
    border-radius: 5px;
    padding: 4px 8px;
    color: white;
    font-size: 15px;
  }
}
.MessageBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px 15px;
  border-bottom: 1px solid rgb(242, 237, 237);
  p {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
  }
}
.myControlClassName {
  max-width: 90%;
  border-radius: 10px;
  padding: 0rem;
  border: 1px solid blue;
}

.TableItem {
  padding: 5px 100px;
}
@media only screen and (max-width: 820px) {
  .RightBarNewCustomer {
    // border: 1px solid blue;
    width: 100%;
  }
  .Icon-containerNewCustomer {
    width: 20rem;
    height: 2.5rem;
    padding: 0px 10px;
    // border: 1px solid red;
    position: relative;
    right: 1rem;
  }

  .iconsNewCustomer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 6rem;
    height: 1.9rem;
    border-radius: 5px;
    padding: 5px;
    cursor: pointer;
    margin: 0px 3px;
    // border: 1px solid red;
  }
  .Input-and-iconsNewCustomer {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    // width: 13rem;
    height: 2.2rem;
    cursor: pointer;
    border-bottom: 1.5px solid #070647;
    font-size: 12px;
    margin-right: 1rem;
    padding-bottom: 2px;
  }
  .searchIconNewCustomer {
    font-size: 10px;
    border: none;
  }
  .Input-fieldNewCustomer {
    background-color: transparent;
    width: 5rem;
    outline: none;
    font-size: 10px;
    // margin-right: 40px;
    // border: 1px solid red;
    align-items: flex-start;
  }
  .Create-iconsNewCustomer {
    background-color: #060649;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 6rem;
    height: 1.9rem;
    border-radius: 5px;
    padding: 0px 5px;
    cursor: pointer;
    margin-right: 10px;
    // border: 1px solid red;
  }

  .AddIcon-styleNewCustomer {
    color: white;
    border: 1px solid #d4cfcf;
    border-radius: 50%;
    margin-right: 10px;
    font-size: 10px;
  }
  .Create-iconsNewCustomer {
    background-color: #060649;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 6rem;
    height: 1.9rem;
    border-radius: 5px;
    padding: 0px 5px;
    cursor: pointer;
    margin-right: 10px;
    // border: 1px solid red;
  }

  //------------------

  .MessageNewCustomer {
    width: 15%;
    // border: 1px solid rgb(31, 64, 107);
    font-size: 10px;
  }
}

table {
  border-collapse: separate;
  border-spacing: 0 0.4rem;
  tbody {
    tr {
      margin: 5px 2px;
      background-color: #f8f8f8;
      border-radius: 5px;
    }
  }

  td {
    padding: 2px 10px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    /* identical to box height, or 157% */

    /* Dark green */

    color: #1a3447;
  }
  th {
    padding: 2px 10px;
    p {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      color: #1a3447;
    }
  }
  span {
    font-family: "Roboto";
  }
}

.myPlaceholderClassName {
  border: "2px solid red !important";
}
.myDropdownMenuClass {
  left: auto !important;
  right: -100 !important;
  top: 0 !important;
  z-index: 99999;
}
