.login-page {
  // Not Verified popup
  .password-container-auth {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 2.5rem;
    background-color: white;
    width: 98%;
    border: 1px solid #ccc;
    font-size: 0.8rem;
    border-radius: 0.5rem;
    input {
      width: 90%;
      border: none;
      outline: none;
      font-size: 0.8rem;
    }
    // OTP UI

    .show-password-icon {
      cursor: pointer;
      height: 50%;
      margin-left: 0.2rem;
    }
  }
  .password-container-auth:hover {
    bottom: 1px solid #9bd5bc;
  }
  .notificationCard {
    width: 80%;
    height: 100%;
    background: rgb(201, 235, 210);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 20px;
    gap: 10px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.123);
    border-radius: 20px;
  }
  .ResendLink {
    color: #011cee;
    font-size: 0.8rem;
    font-weight: 500;
    cursor: pointer;
  }
  // .bellIcon {
  //   width: 50px;
  //   margin: 20px 0px;
  // }

  .bellIcon path {
    fill: rgb(168, 131, 255);
  }

  .notificationHeading {
    color: rgb(221, 13, 13);
    font-weight: 600;
    font-size: 0.8em;
  }

  .notificationPara {
    color: rgb(41, 38, 38);
    font-size: 0.7em;
    font-weight: 600;
    text-align: center;
    font-family: "Roboto";
  }

  .buttonContainer {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
  }
  .AllowBtn {
    width: 120px;
    background-color: rgb(168, 131, 255);
    color: white;
    border: none;
    border-radius: 20px;
    font-size: 0.7em;
    font-weight: 600;
    cursor: pointer;
    text-align: center;
    margin: 0 auto;
  }

  .NotnowBtn {
    width: 120px;
    color: rgb(214, 206, 233);
    background-color: rgb(32, 30, 30);
    border: none;
    font-weight: 600;
    font-size: 0.7em;
    cursor: pointer;
    border-radius: 20px;
  }

  .NotnowBtn:hover {
    background-color: rgb(239, 227, 255);
    color: rgb(54, 54, 76);
  }

  .AllowBtn:hover {
    background-color: rgb(153, 110, 255);
  }
  // closing---
  .app-logo {
    align-self: flex-start;
    margin: 5% 0 0 5%;
  }
  .welcome-area {
    margin-bottom: 20%;
    text-align: start;
  }
  .ForgetPassword {
    display: flex;
    align-self: flex-end;
    color: rgb(12, 2, 189);
    font-size: 0.7rem;
    font-family: "Roboto";
    cursor: pointer;
    padding-left: 0.2rem;
    margin-top: 0.5rem;
  }
  .login__container {
    width: 50%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    padding: 20px;
    .BackToLoginPage {
      display: flex;
      border-radius: 0.5rem;
      margin: 1rem auto;
      background-color: rgb(220, 109, 78);
      padding: 0.15rem 1rem;
      color: #fff;
      font-size: 0.8rem;
      font-weight: 500;
    }
    .OTP_Heading {
      text-align: center;
      font-size: 1.5rem;
      font-weight: 600;
    }
    .VerificationContainer {
      background-color: #9bd5bc;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin: 1rem auto;
      border-radius: 0.5rem;
      .VerificationText {
        color: #333;
        font-family: "Roboto";
        font-size: 0.8rem;
        padding:0.5rem 1rem;
      }
    }
    @media screen and (max-width: 1000px) {
      width: 70%;
      border: 1px solid #ccc;
    }

    @media screen and (max-width: 640px) {
      width: 80%;
      border: 1px solid #ccc;
    }
  }

  .login__container > h1 {
    font-weight: 500;
    margin-bottom: 20px;
  }

  .login__container > form > h5 {
    margin-bottom: 0.1rem;
    font-size: 0.8rem;
  }

  .login__container {
    .input-userID-auth {
      height: 2.5rem;
      margin-bottom: 10px;
      background-color: white;
      width: 98%;
      padding: 0 0.5rem;
      border: 1px solid #ccc;
      font-size: 0.8rem;
      border-radius: 0.5rem;
      outline: none;
    }
    .input-userID-auth :hover {
      border: 1px solid #9bd5bc;
    }
  }
  .login__container > p {
    margin-top: 15px;
    font-size: 0.75em;
  }

  .login__signInButton {
    font-size: 0.8rem;
    border-radius: 0.3rem;
    width: 100%;
    height: 2.25rem;
    border: 1px solid;
    margin-top: 0.5rem;
    border-color: #a88734 #9c7e31 #846a29;
    cursor: pointer;
  }

  .remember-me {
    font-size: 0.8rem;
    left: -18px;
    position: relative;
  }

  .signInText {
    font-weight: 700 !important;
    //   font-family: "roboto";
  }
}
.OTP_input {
  width: 100%;
  height: 2rem;
  border: none;
  outline: none;
  font-size: 0.8rem;
  padding-left: 0.2rem;
  border: 0.5px solid #9bd5bc;
  border-radius: 5px;
  text-align: center;
}
