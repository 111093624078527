.Chat_container {
  width: 100%;
  height: calc(100vh - 140px);
  display: flex;
  flex-direction: column;
  .CustomerMessage_Container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 98%;
    .Customer_LastActive {
      margin: 0.5rem 0;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      .LastSeen {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        // border: 1px solid red;
      }
      h5 {
        color: #000;
        font-size: 0.7rem;
        text-align: center;
        padding-right: 1rem;
      }
      span {
        font-size: 0.6rem;
        align-items: center;
        color: rgba(0, 0, 0, 0.6);
      }
      img {
        width: 2rem;
        border-radius: 50%;
        margin-left: 0.5rem;
      }
    }
    .CustomerMessage {
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      h3 {
        background-color: #42426e;
        color: #fff;
        border-radius: 0.8rem;
        padding: 0.5rem;
        font-size: 0.6rem;
        font-family: "roboto";
      }
    }
  }
  .AdminReminder {
    width: 36%;
    justify-content: space-between;
    text-align: center;
    display: flex;
    padding: 1.5rem 0;
    // border: 1px solid red;
    align-items: center;
    margin: 0 auto;
    img {
      width: 0.9rem;
    }
    span {
      text-align: center;
      font-size: 0.6rem;
      color: #ffa100;
    }
  }
  .AdminMessage_Container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 98%;
    .Admin_LastActive {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      h5 {
        margin-left: 0.5rem;
        color: #000;
        font-size: 0.7rem;
        text-align: center;
        padding-right: 1rem;
      }
      span {
        font-size: 0.6rem;
        align-items: center;
        color: rgba(0, 0, 0, 0.6);
      }
      img {
        width: 2rem;
        border-radius: 50%;
      }
    }
    .AdminMessage {
      height: auto;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: flex-start;
      h3 {
        background-color: #42426e;
        color: #fff;
        border-radius: 0.8rem;
        padding: 0.5rem;
        font-size: 0.6rem;
        font-family: "roboto";
      }
    }
  }
}
// prject details contianer
.DetialMainContainer {
  height: calc(100vh - 190px);
  display: flex;
  justify-content: center;
  margin: 0 auto;
}
.Card {
  width: 60%;
  background-color: #fff;
  border-radius: 5px;
  overflow-y: auto;
  padding-bottom: 3%;
  height: 100%;
  margin-bottom: 1rem;
  h3 {
    text-align: center;
    padding: 1rem 0;
  }
}
.LocationContainer {
  width: fit-content;
}
.icon_Text {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin: 0.2rem 0.8rem;
  .Location_continer {
    display: flex;
    align-items: center;
    font-family: "roboto";
    font-size: 0.9rem;
    color: #000;
    margin-left: 0.2rem;
    .locations {
      padding: 0.3rem;
      font-family: "roboto";
      font-size: 0.7rem;
      color: #616b7a;
    }
  }
  span {
    text-align: center;
    padding: 0.3rem 0.5rem;
    color: #26405e;
    font-family: "roboto";
  }
}
.ProjectId {
  padding: 0.2px 0.8rem;
  color: #26405e;
  font-family: "roboto";
}
.CategoryContainer {
  border: 0.5px solid grey;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  padding: 0.1rem 0.3rem;
  // max-width: 100%;
  margin: 0.3rem 0.5rem;
  width: fit-content;
  span {
    color: #26405e;
    font-family: "roboto";
    font-size: 0.8rem;
    padding-left: 0.5rem;
  }
}
.Job_Dis_Title {
  color: #26405e;
  font-family: "roboto";
  font-size: 1rem;
  font-weight: "400";
  padding: 0.2rem 0.5rem;
}
.Job_discription {
  padding: 0.3rem 0.5rem;
  color: #26405e;
  font-family: "roboto";
  font-size: 0.8rem;
}
.Images_demo_container {
  width: 100%;
  margin: 2rem auto;
  height: 500px;
}
.Image_demo {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  position: relative;
  border: 0.5px solid #38ab7c;
  overflow: hidden;
  border-radius: 0.3rem;
  height: 500px;
  h3 {
    padding: 0.5rem 0.2rem;
    color: #000;
    font-size: 0.8rem;
    font-weight: "500";
    font-family: "roboto";
  }
}
.Image_demo img {
  border-radius: 0.3rem;
  max-width: 100%;
  object-fit: cover;
  margin: 0.5rem auto;
  padding: 0.5rem 1rem;
}

.Image_scroll_container {
  width:100%;
  height: 600px;
  overflow-x: auto;
  overflow-y: auto;
  white-space: nowrap;
  margin-bottom: 0.5rem;
}
.Video_scroll{
  width:98%;
  height: auto;
}
.Download_button{
  display: flex;
  margin:25% auto ;
  color: #000;
  font-size: 1.8rem;
  font-family: "roboto";
}
.slick-slider {
  width: 100%;
}

.slick-slide {
  padding: 0 5px;
}

h3 {
  padding: 0.5rem 0.2rem;
  color: #000;
  font-size: 0.8rem;
  font-family: "roboto";
}

.Files_Icon {
  width: fit-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  margin: 0.5rem auto;
  border: 0.5px solid rgb(60, 144, 60);
  padding: 0.3rem 1rem;
  border-radius: 0.5rem;
  span {
    font-size: 0.7rem;
    color: #42426e;
  }
}
.Activity_Container {
  width: 20%;
  gap: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: fit-content;
  margin: 3rem auto 0rem auto;
}
.Delete_BTN {
  background-color: red;
  color: #fff;
  font-weight: "500";
  font-size: 0.8rem;
  text-align: center;
  padding: 0.4rem 1.2rem;
  border-radius: 0.5rem;
  cursor: pointer;
}
.Edit_BTN {
  background-color: #38ab7c;
  color: #fff;
  font-size: 0.8rem;
  font-weight: "500";
  text-align: center;
  padding: 0.4rem 1.5rem;
  border-radius: 0.5rem;
  cursor: pointer;
}
//QnA designing

.qna-container {
  margin: 20px 20px 50px 20px;
}

.qna-item {
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 10px;
  margin: 0.5rem 0;
}

.question {
  font-weight: bold;
}

.answer {
  color: #666666;
}

.waiting-response {
  color: #666666;
}

.reply-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  margin: 0.3rem 0.2rem;
}

.ask-button {
  background-color: #28a745;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
}

.dialog {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  margin-top: 20px;
}

.textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.close-button,
.post-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
}

.close-button {
  margin-right: 10px;
}

.post-button {
  background-color: #28a745;
}
.HeadingAndBack {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0.5rem;
  h1 {
    padding-left: 0.2rem;
    color: #26405e;
    font-size: 1.2rem;
    font-weight: 600;
  }
}
.QnAContainer {
  width: 100%;
  height: calc(100vh - 140px);
  display: flex;
  flex-direction: column;
  .QnAcard{
    border:1px solid grey;
    border-radius:0.5rem;
    margin:0.5rem 0.2rem;
  }
  .Questions{
        color: #333;
        padding: 0.5rem;
        font-size: 1rem;
        font-family: "roboto";
        font-weight:500
  }
  .Asked_by{
    font-size: 0.7rem;
    font-family:"Times New Roman";
    margin-left:0.5rem;
    color:#666
  }
  .PlaceholerText{
    font-size: 0.9rem;
    font-family: "roboto";
    color:#222;
    font-weight:500;
    padding:0.5rem 0.5rem
  }
  
}