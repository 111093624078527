.OTPBox {
  width: 20rem;
  height: 14rem;
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 1.1rem;
  .Cross {
    width: 0.8rem;
    display: flex;
    align-self: flex-end;
    margin-top: 2rem;
    margin-right: 1.3rem;
    cursor: pointer;
  }
  h3 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #26405e;
  }
  .OTPValid {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0 25%;
    p {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 0.8rem;
      line-height: 1rem;
      color: #ff7d00;
    }
    img {
      width: 1rem;
    }
  }
  .OTPandCopy {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    gap: 0.6rem;
    h1 {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 600;
      font-size: 1.4rem;
      line-height: 38px;
      text-align: center;
      color: #000000;
    }
    img {
      cursor: pointer;
    }
  }
  h4 {
    display: flex;
    align-self: flex-end;
    padding-right: 3rem;
    cursor: pointer;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 0.8rem;
    line-height: 1rem;
    color: #00309f;
  }
}
.OTPBoxError {
  width: 18rem;
  height: 14rem;
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  justify-content: space-between;
  h5 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    display: flex;
    justify-content: flex-end;
    padding-right: 1.2rem;
    padding-bottom: 1rem;
    color: #26405e;
    cursor: pointer;
  }
  h3 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 19px;
    text-align: center;
    color: #26405e;
  }
  .Cross {
    width: 0.8rem;
    display: flex;
    align-self: flex-end;
    position: relative;
    top: 1.5rem;
    right: 1rem;
    cursor: pointer;
  }
  .SadIcon {
    width: 4rem;
    display: flex;
    align-self: center;
  }
}
