$primary-color: #007bff;
$dropdown-background: white;
$dropdown-border-color: #ddd;
$dropdown-hover-background: #f1f1f1;
$button-padding: 10px;
$dropdown-padding: 10px;
$dropdown-margin-top: 5px;

.dropdown-content {
  position: fixed;
  background-color: $dropdown-background;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 999999999;
  margin-top: $dropdown-margin-top;
  padding: $dropdown-padding;
  border: 1px solid $dropdown-border-color;
  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      padding: 8px 12px;
      cursor: pointer;
      font-weight: 400;
      font-size: 0.8rem;
      font-family: "Roboto";

      &:hover {
        background-color: $dropdown-hover-background;
      }
    }
  }
}

.containerForSearchProBussiness {
  height: calc(100% - 135px);
  width: 100%;
  // border: 1px solid red;
  padding: 10px 15px;

  .NoAdminDataForProBussiness {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    align-items: center;
    img {
      margin-top: 5%;
    }
    h1 {
      padding: 10px;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 600;
      font-size: 1.3rem;
      line-height: 21px;
      letter-spacing: 0.004em;
      text-transform: uppercase;
      color: #000000;
    }
    h3 {
      padding: 5px 10px;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 0.9;
      line-height: 18px;
      letter-spacing: 0.004em;
      color: #797979;
    }
    .NoAdminDataForProBussiness_Titles {
      margin-top: 3%;
      display: flex;
      flex-direction: column;
      align-items: center;
      h3 {
        margin-top: 2%;
      }
    }
  }
}
.table-areaContainer {
  max-height: calc(100% - 60px);
  width: 100%;
  display: flex;
  flex-direction: column;
}
.FirstClildProBusiness {
  display: flex;
  flex-direction: column;
  // align-items: center;
  margin-left: 0.8rem;
  gap: 0.2rem;
  .returingDataDate {
    text-align: start;
  }
}
.underLineTextProBusiness {
  width: 100%;
  color: blue;
  font-weight: 400;
  font-size: 0.8rem;
  font-family: "Roboto";
  cursor: pointer;
  border-bottom: 2px solid blue;
}
.returingDataProBusiness {
  font-weight: 400;
  font-size: 0.7rem;
  font-family: "Roboto";
  color: #616b7a;
}
.HeadingAndSearchSearchProBusinessAccount {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  padding: 0.8rem 0;
  .HeaderText {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    h1 {
      color: #26405e;
      font-weight: 600;
      font-family: "Roboto";
      line-height: 22px;
      font-size: 20px;
      text-align: center;
    }
    p {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 22px;
      text-transform: capitalize;
      color: #26405e;
      width: fit-content;
      text-align: center;
    }
  }
}
.RightBarSearchProBusinessAccount {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  margin-right: 1rem;
}
.filtersProBusinessAccount {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 0.5rem;
  height: 40px;
}
.locationSearchProBusinessAccount {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #129160;
  border-radius: 5px;
  padding: 4px 8px;
  color: white;
  font-size: 12px;
  border: none;
  outline: none;
}
.filterSearchProBusinessAccount {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 5px;
  padding: 4px 10px;
  color: black;
  font-size: 13px;
  border: none;
  outline: none;
}
.SearchSearchProBusinessAccount {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #42426e;
  border-radius: 5px;
  padding: 4px 8px;
  color: white;
  font-size: 12px;
  border: none;
  outline: none;
  margin-top: 0.5rem;
}
.inputDivSearchProBusinessAccount {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 15rem;
  height: 1.9rem;
  // border-radius: 5px;
  border-bottom: 1px solid black;
  input {
    outline: none;
    width: 100%;
    height: 100%;
    margin-left: 5px;
    font-size: 12px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    background: none;
    padding-left: 5px;
  }
}

// Headers ---------------
.HeaderDetailSearchProBusinessAccount {
  // border: 1px solid red;
  height: 80px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 2rem;
  p {
    font-size: 0.8rem;
    font-weight: 500;
    font-family: "Roboto";
  }
}
.UserDetail {
  // flex justify-start items-center
  display: flex;
  justify-content: flex-start;
  align-items: center;
  span {
    border: none;
    font-family: "Roboto";
    color: #1a3447;
    font-size: 0.8rem;
    font-weight: 500;
    margin-left: 0.4rem;
  }
}
.underLineTextSearch {
  width: fit-content;
  color: blue;
  font-weight: 400;
  font-size: 0.8rem;
  font-family: "Roboto";
  cursor: pointer;
  border-bottom: 1px solid blue;
}
.userDIvSearchProBusinessAccount {
  width: 135px;
  // border: 1px solid blue;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.userDIvSearchProBusinessAccount p {
  margin-left: 1.2rem;
  font-family: "Roboto";
}

.CategorySearchProBusinessAccount {
  width: 77px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // border: 1px solid blue;
  //  position: relative;
  // left: 1.5rem;
}

.SubSearchProBusinessAccount {
  width: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // border: 1px solid yellow;
  position: relative;
  right: 0.7rem;
}
.LocationSearchProBusinessAccount {
  width: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // border: 1px solid yellow;
  position: relative;
  right: 2rem;
}
.EmailDivSearchProBusinessAccount {
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  // border: 1px solid green;
  position: relative;
  left: 2rem;
}
.InvoiceSearchProBusinessAccount {
  width: 130px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  // border: 1px solid green;
  position: relative;
  left: 3.3rem;
}
.PaySearchProBusinessAccount {
  width: 130px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  // border: 1px solid green;
  position: relative;
  left: 1.7rem;
}
.MessageSearchProBusinessAccount {
  width: 120px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  // border: 1px solid green;
  position: relative;
  left: 1rem;
}
.ServiceSearchProBusinessAccount {
  width: 130px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  // border: 1px solid green;
  position: relative;
}
@media print {
  .containerForSearchProBussiness {
    display: none;
  }
}
