.StaticMainContainer {
  height: calc(100% - 70px);
  padding: 0.8rem;
  .StaticBidContainer {
    display: flex;
    justify-content: space-between;
    gap: 0.8rem;
    margin-bottom: 1rem;
    .StaticBidDiv {
      // bg-white w-full h-28 rounded-md flex items-center justify-between p-4
      background-color: #fff;
      height: 6rem;
      border-radius: 0.1rem;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1.2rem;
      .DataNumber {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 600;
        font-size: 1.5rem;
        line-height: 1.8rem;
        color: #000000;
      }
      p {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 0.9rem;
        line-height: 1.2rem;
        letter-spacing: 0.25px;
        color: #000000;
        font-size: 01rem;
        font-weight: 500;
      }
      .BidImageContainer {
        // w-12 h-12 rounded-xl flex items-center justify-between
        width: 3rem;
        height: 3rem;
        border-radius: 0.6rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        img {
          width: 2rem;
          height: 2rem;
          margin: auto;
        }
      }
    }
  }
  table {
    border-collapse: separate;
    border-spacing: 0 0.4rem;
    tbody {
      tr {
        margin: 5px 2px;
        background-color: #f8f8f8;
        border-radius: 5px;
        .tableData {
          padding: 0.5rem 0rem 0.5rem 1.4rem;
          font-family: "Roboto";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: #616b7a;
        }
      }
    }

    th {
      padding: 0.5rem 0rem 0.5rem 1.5rem;
      p {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        color: #1a3447;
      }
    }
    span {
      font-family: "Roboto";
    }
    .MoreBTN {
      cursor: pointer;
      margin-left: 5%;
    }
  }
}
.Header_banking {
  font-family: "Roboto" !important;
  font-weight: 500 !important;
  font-size: 0.87rem !important;
  line-height: 16.41px !important;
  color: #26405e !important;
}
.staticContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border: 1px solid white;
  padding: 5px 15px;
}
.ItemsContainer {
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 200px);
  width: 100%;
  margin-top: 0.8rem;
}
.staticContainer h1,
p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: #000000;
  padding: 0.3rem 0;
}
.StaticIconContainer {
  width: 5rem;
  //  border: 1px solid red;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
  cursor: pointer;
  margin-right: 0.5rem;
}
.StaticIconContainer img {
  width: 20px;
  height: 18px;
  margin-left: 0.5rem;
}

.HeaderDetailStatics {
  // border: 1px solid red;
  height: 60px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #f8f8f8;
  margin-bottom: 0.3rem;
  border-radius: 0.2rem;
}
.StateStatics {
  // width: 140px;
  // border: 1px solid blue;
  display: flex;
  align-items: center;
  padding-left: 1.5rem;
  p {
    // margin-left: 40px;
    font-family: "Roboto";
  }
}
.footer {
  // background-color: green;
  // border-top: 2px solid red;
  position: fixed;
  // width: 100%;
  bottom: 0;
  // color: white;
  // font-size: 25px;
}

.CountryStatics {
  // width: 130px;
  display: flex;
  align-items: center;
  // border: 1px solid blue;
  // margin-left: 1.5rem;
}

.ZipStatics {
  // width: 90px;
  display: flex;
  align-items: center;
  // border: 1px solid yellow;
}
.LocationStatics {
  // width: 95px;
  display: flex;
  align-items: center;
}
.BidsStatics {
  display: flex;
  // width: 100px;
  justify-content: center;
  align-items: center;
  // border: 1px solid green;
  // margin-left: 1rem;
}
.CustomerStatics {
  display: flex;
  // width: 150px;
  justify-content: center;
  align-items: center;
  // border: 1px solid green;
}
.BusinessStatics {
  display: flex;
  // width: 150px;
  justify-content: center;
  align-items: center;
  margin-right: 1.5rem;
  // border: 1px solid green;
}
.LocationText {
  border: 0;
  font-family: "Roboto";
  position: relative;
  left: 2rem;
}
.BidsText {
  border: 0;
  font-family: "Roboto";
  margin-right: 10;
  margin-right: 1rem;
}
.ZipCode {
  border: 0;
  font-family: "Roboto";
  position: relative;
  left: 0.7rem;
}
.Country {
  border: 0;
  font-family: "Roboto";
}
.State {
  border: 0;
  font-family: "Roboto";
}
.customerText {
  border: 0;
  font-family: "Roboto";
  position: relative;
  left: 2rem;
}
//iPad---

@media only screen and (max-width: 820px) {
  .StaticMainContainer {
    height: calc(100% - 70px);
    padding: 0.8rem;
    .StaticBidContainer {
      display: flex;
      justify-content: space-between;
      gap: 0.8rem;
      margin-bottom: 1rem;
      .StaticBidDiv {
        background-color: #fff;
        height: 5rem;
        border-radius: 0.1rem;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1.2rem;
        .DataNumber {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 600;
          font-size: 1.5rem;
          line-height: 1.8rem;
          padding-top: 0.5rem;
          color: #000000;
        }
        p {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 400;
          font-size: 0.9rem;
          line-height: 1.2rem;
          letter-spacing: 0.25px;
          color: #000000;
          font-size: 01rem;
          font-weight: 500;
        }
        .BidImageContainer {
          width: 3rem;
          height: 3rem;
          border-radius: 0.6rem;
          display: flex;
          justify-content: space-between;
          align-items: center;
          img {
            width: 2rem;
            height: 2rem;
            margin: auto;
          }
        }
      }
    }
  }
  .LocationText {
    position: relative;
  }
  .LocationStatics {
    // width: 95px;
    display: flex;
    align-items: center;
    position: relative;
    left: 1rem;
  }
  .BidsStatics {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    left: 1.5rem;
  }
  .staticContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    border: 1px solid white;
    padding: 5px 15px;
    // border: 1px solid red;
    h1,
    p {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 1rem;
      line-height: 20px;
      letter-spacing: 0.25px;
      color: #000000;
      // border: 1px solid red;
    }
  }
  .ItemsContainer {
    display: flex;
    flex-direction: column;
    border: 1px solid red;
  }

  .StaticIconContainer {
    // width: 5rem;
    //  border: 1px solid red;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px;
    cursor: pointer;
    margin-right: 0.8rem;
  }
  .StaticIconContainer img {
    width: 20px;
    height: 18px;
    margin-left: 0.6rem;
  }

  .HeaderDetailStatics {
    // border: 1px solid red;
    height: 60px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #f8f8f8;
    // padding-right: 11.5rem;
    margin-bottom: 0.3rem;
    border-radius: 0.2rem;
    p {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      letter-spacing: 0.25px;
      color: #000000;
    }
  }

  .StateStatics {
    // width: 140px;
    // border: 1px solid blue;
    display: flex;
    align-items: center;
    padding-left: 1.5rem;
  }
  .StateStatics {
    p {
      // margin-left: 40px;
      font-family: "Roboto";
      font-size: 1rem;
    }
  }

  .CountryStatics {
    // width: 130px;
    display: flex;
    align-items: center;
    // border: 1px solid blue;
    // margin-left: 1.5rem;
  }

  .ZipStatics {
    // width: 90px;
    display: flex;
    align-items: center;
    // border: 1px solid yellow;
  }
  .LocationStatics {
    // width: 95px;
    display: flex;
    align-items: center;
  }
  .BidsStatics {
    display: flex;
    // width: 100px;
    justify-content: center;
    align-items: center;
    // border: 1px solid green;
    // margin-left: 1rem;
  }
  .CustomerStatics {
    display: flex;
    // width: 150px;
    justify-content: center;
    align-items: center;
    // border: 1px solid green;
  }
  .BusinessStatics {
    display: flex;
    // width: 150px;
    justify-content: center;
    align-items: center;
    margin-right: 1.5rem;
    // border: 1px solid green;
  }
}
