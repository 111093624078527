.TempCustomerParentContainer {
  height: calc(100% - 140px);
  margin: 0;
  .underLineText {
    color: blue;
    font-weight: 400;
    font-size: 0.8rem;
    font-family: "Roboto";
    border-bottom: 2px solid blue;
    cursor: pointer;
    width: fit-content;
  }
  .FirstClild {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 0.8rem;
    gap: 0.2rem;
  }
  .returingDataDate {
    font-weight: 400;
    font-family: "Roboto";
    font-size: 0.7rem;
    color: #616b7a;
  }
  .returingDataTemp {
    font-weight: 400;
    font-family: "Roboto";
    font-size: 0.8rem;
    color: #616b7a;
  }
  .inner-containerTempCustomer {
    height: 100%;
    width: 100%;
    padding: 0rem 0.9rem;
  }

  .QnATextTemp {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 0.6rem;
    line-height: 1.4rem;
    color: #1a3447;
    background-color: #ff8a00;
    border-radius: 0.8rem;
    padding: 0.15rem;
    border: 1px solid black;
  }
}
.password_validate {
  color: red;
  font-size: 0.7rem;
  font-family: "lora";
  padding: 0.5rem 1rem;
}
.ApplyBTNCustomer {
  width: 2rem;
  height: 2rem;
  margin-left: 0.2rem;
  img {
    width: 2rem;
    height: 2rem;
    padding: 0.2rem 0;
  }
}
.changePasswordBTNCustomer {
  cursor: pointer;
  width: 2rem;
  height: 2rem;
  box-shadow: 1px 1px solid rgb(91, 91, 215);
  margin-left: 0.8rem;
  border-bottom: 1px solid blue;
  img {
    width: 2rem;
    height: 2rem;
    padding: 0.2rem 0;
  }
}
.returingData {
  color: #616b7a !important;
  font-size: 0.85rem !important ;
  font-family: "roboto" !important;
}
.hide_show {
  width: 25px;
  height: 25px;
  outline: none;
  border: none;
  cursor: pointer;
  margin-left: 0.5rem;
}

.passWordContainerTempcustomer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-self: center;
  height: 1.5rem;
  font-size: 0.85rem;
  font-weight: 500;
  color: #343a7f;
  .WrittenPassword {
    letter-spacing: 0.1rem;
    font-size: 0.85rem;
    font-weight: 500;
    color: #343a7f;
    cursor: pointer;
    width: 2.58rem;
  }
  .WrittenPassword_star {
    letter-spacing: 0.2rem;
    font-size: 1rem;
    font-weight: 500;
    color: #343a7f;
    cursor: pointer;
    width: 2.58rem;
  }
  .Passwords {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: "Roboto";
    padding: 0.3rem 0;
    font-size: 0.7rem;
    font-weight: 500;
    color: #081383;
    margin-left: 0.2rem;
    .noneStar {
      font-size: 0.85rem;
      font-weight: 500;
      color: #343a7f;
      letter-spacing: 0.3rem;
    }
  }
  .eyeButton {
    outline: none;
    border: none;
    margin-right: 0.5rem;
    margin-bottom: 0.2rem;
    cursor: pointer;
  }
}
.ChangePassDialog {
  width: 20rem;
  // height: 7rem;
  display: flex;
  flex-direction: column;
  input {
    margin: 0 0.5rem;
    border: 0.5px solid grey;
    padding: 0.5rem 0.5rem;
    border-radius: 1rem;
    outline: none;
    font-size: 0.7rem;
    font-weight: "500";
  }
  .change_pass_btns_customer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0.5rem 0;
  }
  span {
    font-size: 1rem;
    padding: 0.5rem 1rem;
    font-family: "Roboto";
  }
  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.5rem auto;
    cursor: pointer;
    background-color: #38ab7c;
    color: #fff;
    font-family: "Roboto";
    border-radius: 1.5rem;
    height: 1.5rem;
    font-size: 0.8rem;
    padding: 0.5rem 1rem;
  }
  .btn_disable {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.5rem auto;
    cursor: pointer;
    background-color: #585f5c;
    color: #fff;
    font-family: "Roboto";
    border-radius: 1.5rem;
    height: 1.5rem;
    font-size: 0.8rem;
    padding: 0.5rem 1rem;
  }

  .CloseBTN {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.5rem auto;
    cursor: pointer;
    background-color: #cd6256;
    color: #fff;
    font-family: "Roboto";
    border-radius: 1.5rem;
    height: 1.5rem;
    font-size: 0.8rem;
  }
}
.HeadingAndSearchTempCust {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0.2rem;
  h1 {
    color: #26405e;
    font-weight: 600;
    font-family: "Roboto";
    line-height: 22px;
    // padding-left: 0.9rem;
    text-align: center;
    font-size: 20px;
    margin-top: 5px;
  }
}
.Icon-containerTempCust {
  width: 15rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 2.2rem;
  padding: 0px 10px;
  // margin-top: 5px;
  //   border: 1px solid red;
}
.iconsTempCust {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 6rem;
  height: 1.9rem;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
  margin: 0px 3px;
  //   border: 1px solid red;
}
.Input-and-iconsTempCust {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 8rem;
  height: 1.9rem;
  cursor: pointer;
  border-bottom: 1.5px solid #070647;
  margin-right: 1rem;
  .searchIconTempCust {
    color: #8394a4;
    font-size: 1.4rem;
  }
}
.AddIcon-styleTempCust {
  width: 1.5rem;
}
.passwordContainer {
  display: flex;
  flex-direction: row;
}
.Input-fieldTempCust {
  background-color: transparent;
  width: 4rem;
  outline: none;
  font-size: 14px;
  margin-top: 5px;
  margin-right: 40px;
}
.Create-iconsTempCust {
  background-color: #060649;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  // width: 5rem;
  // height: 1.9rem;
  border-radius: 5px;
  padding: 0.2rem 0.5rem;
  cursor: pointer;
  margin-right: 10px;
  p {
    color: #ffffff;
    font-size: 0.9rem;
  }
}

.AddIcon-styleTempCust {
  color: white;
  border: 1px solid #d4cfcf;
  border-radius: 50%;
  margin-right: 10px;
}

.locationByTempCust {
  display: flex;
  justify-content: center;
  align-items: center;
  //   background-color: #129160;
  border-radius: 5px;
  padding: 4px 8px;
  color: white;
  font-size: 12px;
  border: none;
  outline: none;
}
.SearchByTempCust {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #42426e;
  border-radius: 5px;
  padding: 4px 8px;
  color: white;
  font-size: 12px;
  border: none;
  outline: none;
}
.inputDivTempCust {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 15rem;
  height: 1.9rem;
  // border-radius: 5px;
  border-bottom: 1px solid black;
}
.input-fieldTempCust {
  outline: none;
  width: 100%;
  height: 100%;
  margin-left: 5px;
  font-size: 12px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  background-color: white;
  padding-left: 5px;
}
.HeaderDetailTempCust {
  //   border: 1px solid red;
  height: 60px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 1.5rem;
}
.uploadDiv {
  width: 42px;
  height: 42px;
  background: linear-gradient(
      0deg,
      rgba(116, 116, 116, 0.8),
      rgba(116, 116, 116, 0.8)
    ),
    url("../../../assets/icons/Frame23.svg");
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.HeadingInput {
  box-sizing: border-box;
  width: 8rem;
  height: 2rem;
  padding-left: 10px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  outline: none;
  border: none;
}
.InputHeadingAndSearch {
  width: 99%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 1.5rem;
  align-items: center;
  margin: 5px 0px;
  height: 55px;
  background-color: #f8f8f8;
  border-radius: 5;
  border-radius: 6px;

  .leftIcons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 12rem;
    position: relative;
    left: 1rem;
  }

  .fiter {
    display: flex;
    justify-content: space-between;
    padding: 5px 10px;
    border: 1px solid #c0c0c0;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    right: 2.2rem;
    //   border: 1px solid red;

    p {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #616b7a;
    }
    img {
      color: #616b7a;
      margin-left: 15px;
    }
  }
  .filter2 {
    box-sizing: border-box;
    width: 293px;
    height: 34px;
    background: #ffffff;
    border: 1px solid #c0c0c0;
    border-radius: 4px;
    position: relative;
    right: 3rem;
  }
}
.unfillStars {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 7rem;
  height: 2rem;
  //   border: 1px solid red;
  position: relative;
  right: 7.5rem;
}
.saveIcons {
  display: flex;
  justify-content: space-between;
  width: 3rem;
  height: 20px;
  // border: 1px solid red;
  padding: 0px 5px;
  position: relative;
  right: 2rem;
  img {
    cursor: pointer;
    height: 15px;
    width: 15px;
  }
}

.blank {
  width: 10rem;
  //   border: 1px solid red;
  position: relative;
  right: 4rem;
}
.userDIvTempCust {
  width: 135px;
  // border: 1px solid blue;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 1rem;
}
.userDIvTempCust p {
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 500;
  font-family: "Roboto";
}

.DateDivTempCust {
  width: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // border: 1px solid blue;
  position: relative;
  right: 1.8rem;
}

.LocationDivTempCust {
  width: 160px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // border: 1px solid yellow;
  position: relative;
  right: 3.5em;
  p {
    margin-left: 5px;
    font-size: 16px;
  }
}
.locationData {
  border: 0;
  font-family: Roboto;
  font-size: 14px;
  color: #1a3447;
  position: relative;
  right: 3.2rem;
  // border: "1px solid blue",
}
.QNA {
  border: 0;
  font-family: "Roboto";
  font-size: 14px;
  color: #1a3447;
  position: relative;
  right: 5.8rem;
  /* table content */
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
.ThreeDots {
  border: 0;
  position: relative;
  right: 3.5rem;
  cursor: pointer;
}
.bidsColumn {
  border: 0;
  font-family: "Roboto";
  position: relative;
  right: 8.5rem;
  font-size: 14px;
  color: #1a3447;
}
.carbDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  width: 32px;
  height: 26px;
  background-color: #26405e;
  border-radius: 6px;
  color: #ffffff;
  font-size: 14px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}
.AddressDivTempCust {
  width: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // border: 1px solid yellow;
  position: relative;
  right: 4.5rem;
  p {
    font-size: 14px;
    font-weight: 500;
    font-family: "Roboto";
  }
}
.EmailDivTempCust {
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  // border: 1px solid green;
  position: relative;
  right: 5rem;
}
.VideosTempCust {
  width: 90px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  // border: 1px solid green;
  position: relative;
  right: 1.3rem;
}
.Stars {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 7rem;
  height: 2rem;
}
.SecDiv {
  display: flex;
  flex-direction: row;
  padding-top: 5;
  align-items: center;
  span {
    border: 0;
    font-family: "Roboto";
    color: #1a3447;
    font-size: 14px;
    font-size: normal;
    font-weight: 500;
    line-height: 22px;
    // border:'1px solid red'
  }
}
// Responsive
@media only screen and (max-width: 820px) {
  .inner-container {
    // border: 1px solid red;

    .HeadingAndSearchTempCust {
      .Icon-containerTempCust {
        width: 70%;
        width: 18rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 2.2rem;
        padding: 0px 10px;
        // margin-top: 5px;
      }
    }
    .Icon-containerTempCust {
      // border: 1px solid red;
      .Input-fieldTempCust {
        background-color: transparent;
        width: 4rem;
        outline: none;
        font-size: 14px;
        margin-top: 5px;
        margin-right: 40px;
        // background-color: #fff;
      }
    }
  }
  .Create-iconsTempCust {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 7rem;
    height: 2rem;
    border-radius: 5px;
    padding: 5px;
    cursor: pointer;
    margin: 0px 3px;
    // border: 1px solid red;
  }
  .AddIcon-styleTempCust {
    width: 10px;
  }
  p {
    font-size: 10px;
  }

  .LocationDivTempCust {
    width: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // border: 1px solid rgb(83, 30, 8);
    position: relative;
    left: 2.5rem;
    p {
      margin-left: 5px;
      font-size: 13px;
      font-weight: 500;
      font-family: " Roboto";
    }
  }
  .HeaderDetailTempCust {
    // border: 1px solid red;
    .userDIvTempCust {
      width: 110px;
      // border: 1px solid rgb(14, 202, 46);
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      left: 1rem;
    }
    .userDIvTempCust p {
      font-family: "Roboto";
      font-size: 12px;
      font-weight: 600;
      font-family: "Roboto";
    }
    .DateDivTempCust {
      width: 80px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      // border: 1px solid blue;
      position: relative;
      left: 1rem;
    }
  }
  .TnCAccTcell {
    // border: 1px solid red;

    width: 9rem;
    height: 4rem;
    img {
      width: 25px;
    }
    .uDiv {
      span {
        font-size: 10px;
      }
    }
  }
  .locationData {
    // border: 1px solid red;
    width: 15rem;
    span {
      border: 0;
      font-family: "Roboto";
      color: #1a3447;
      font-size: 13px;
      font-size: normal;
      font-weight: 500;
      line-height: 22px;
      // border:'1px solid red'
    }
    .carbDiv {
      // border:1px solid red
      margin-left: 10px;
    }
  }
  .bidsColumn {
    // border: 1px solid blue;
    position: relative;
    right: 5rem;
  }
  .AddressDivTempCust {
    width: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // border: 1px solid yellow;
    position: relative;
    left: 1rem;
    p {
      font-size: 12px;
      font-weight: 600;
      font-family: "Roboto";
    }
  }
  .EmailDivTempCust {
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    // border: 1px solid green;
    position: relative;
    right: 1rem;
  }
  .QNA {
    border: 0;
    font-family: "Roboto";
    font-size: 14px;
    color: #1a3447;
    position: relative;
    right: 5rem;
    // border: 1px solid red;
  }
  .VideosTempCust {
    width: 80px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    // border: 1px solid green;
    position: relative;
    right: 1rem;
  }
  .ThreeDots {
    border: 0;
    position: relative;
    right: 3.5rem;
    cursor: pointer;
    // border: 1px solid green;
  }
  .Cells {
    width: 99%;
  }

  //---------------------------

  .InputHeadingAndSearch {
    width: 99%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 5px 0px;
    height: 55px;
    background-color: #f8f8f8;
    border-radius: 5;
    border-radius: 6px;
    // border: 1px solid red;
    .leftIcons {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 8rem;
      padding-left: 0.5rem;
    }
    .uploadDiv {
      width: 35px;
      height: 35px;
      background: linear-gradient(
          0deg,
          rgba(116, 116, 116, 0.8),
          rgba(116, 116, 116, 0.8)
        ),
        url("../../../assets/icons/Frame23.svg");
      border-radius: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    input {
      box-sizing: border-box;
      width: 70px;
      height: 24px;
      border: 1px solid #c0c0c0;
      border-radius: 4px;
      outline: none;
      padding-left: 10px;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      // border: 1px solid blue;
      position: relative;
      left: 10px;
    }
    .fiter {
      display: flex;
      justify-content: space-between;
      padding: 5px 10px;
      border: 1px solid #c0c0c0;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      left: 1rem;
      // border: 1px solid red;
      width: 10rem;

      p {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 22px;
        color: #616b7a;
      }
      img {
        color: #616b7a;
        margin-left: 5px;
      }
    }
    .filter2 {
      width: 7rem;
      height: 34px;
      background: #ffffff;
      border: 1px solid #c0c0c0;
      border-radius: 4px;
      position: relative;
      right: 1.5rem;
      font-size: 12px;
    }
    .unfillStars {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 9rem;
      height: 2rem;
      // border: 1px solid red;
      position: relative;
      right: 1.5rem;
      img {
        width: 18px;
      }
    }
    .saveIcons {
      display: flex;
      justify-content: space-between;
      width: 5rem;
      height: 20px;
      // border: 1px solid red;
      padding: 0px 5px;
      position: relative;
      right: 1rem;
      img {
        cursor: pointer;
        height: 15px;
        width: 15px;
      }
    }
    .blank {
      width: 5rem;
      position: relative;
      right: 2rem;
    }
  }
}
