.LHContainer {
  width: 50rem;
  height: 45vh;
  .topItems {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1.5rem 0.2rem 1.5rem;
    width: 100%;
    border-bottom: 2px solid #26405e;
    .Headings {
      display: flex;
      flex-direction: column;
      h3 {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 500;
        font-size: 1.2rem;
        line-height: 19px;
        color: #26405e;
      }
      p {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #616b7a;
        padding-top: 1rem;
        padding-left: 0.1rem;
      }
    }

    .searchContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 0;
      // height: 2rem;
      margin-right: 1rem;
      width: 35%;
      .FilterBox {
        // border: 1px solid red;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        h2 {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 500;
          font-size: 0.8rem;
          line-height: 19px;
          color: #26405e;
          padding-bottom: 0.5rem;
        }
        .CalnederIcon {
          cursor: pointer;
          width: 0.8rem;
        }
      }
    }
    .Cross {
      color: #26405e;
      cursor: pointer;
    }
  }
}
.pagination {
  border: 0;
  display: flex;
  align-self: flex-end;
  padding: 0.5rem 1rem;
}
.pagination > li {
  list-style: none;
  border: 0.3px solid;
  border: none;
}
.pagination > li > a,
.pagination > li > span {
  float: left;
  padding: 0.2rem 1.2rem;
  line-height: 1.5;
  border: 1px solid #ddd;
  margin-left: -1px;
}

.pagination > li.active > a {
  color: #fff;
  background-color: #38ab7c;
  border-color: #38ab7c;
}
.pagination > li > a:hover {
  background-color: #38ab7c;
  color: white;
  cursor: pointer;
  border: 1px solid rgb(120, 186, 20);
}
.pagination > li:first-child > a,
.pagination > li:first-child > span {
  margin-left: 0;
}
