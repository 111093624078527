.createAdminMainContainer {
  // border: 1px solid red;
  padding: 1rem;
  height: calc(100% - 70px);
  .createAdminMainContainerHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .createAdminMainContainerHeaderButtons {
      display: flex;
      justify-content: center;
      align-items: center;
      button {
        margin-right: 1.2rem;
        margin-top: 0.2rem;
      }
      h1 {
        font-family: "roboto";
        font-size: 1.5rem;
        font-weight: 600;
      }
    }
  }
  .createAdminMainContainerHeaderSaveButtons {
    display: flex;
    justify-content: center;
    align-items: center;
    .cancelBtn {
      margin-left: 0.2rem;
    }
    .saveBtn {
      margin-left: 0.2rem;
      img {
        color: #fff;
      }
    }
  }
  .createAdminBottomContainer {
    max-height: calc(100% - 50px);
    width: 100%;
    margin-top: 1.2rem;
    display: flex;
    justify-content: center;
    .UploadButtonContainer {
      // border: 1px solid red;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }
  .ImageContainer {
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 5rem;
      height: 5rem;
      border-radius: 50%;
    }
  }
}
.card {
  height: 16rem;
  width: 100%;
  background-color: #fff;
  padding: 1.25rem 0.75rem;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  margin-bottom: 0.75rem;
  .card__inner-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 100%;

    .card__image-area {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .userImg {
        height: 3rem;
        width: 3rem;
      }
    }

    .admin-name {
      font-size: 16px;
      line-height: 19px;
      /* identical to box height */

      color: #000000;

      /* Inside auto layout */

      flex: none;
      order: 0;
      flex-grow: 0;
      text-align: center;
    }
    .admin-id {
      font-size: 14px;
      line-height: 16px;

      /* button linear */

      color: #38ab7c;
      text-align: center;
    }

    .ip-address-text {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      text-align: center;

      /* icon color */

      color: #26405e;
    }
    .ip-address-number {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      text-align: center;

      /* icon color */

      color: #37516f;
    }

    .admin {
      padding: 4px 8px;
      gap: 10px;

      width: 92px;
      height: 22px;

      background: #f3f3f3;
      border-radius: 20px;

      &__text {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        /* identical to box height */

        color: #000000;
      }
    }
  }
}

.saveBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 16px 10px 10px;

  width: 93px;
  height: 44px;

  /* button linear */

  background: #38ab7c;
  border-radius: 6px;

  .text {
    font-family: "roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 110%;
    /* or 18px */

    /* White */

    color: #ffffff;
    margin-left: 0.2rem;
  }
}

.cancelBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 16px 10px 10px;
  gap: 6px;

  width: 107px;
  height: 44px;

  /* Line */

  border-radius: 6px;
  .text {
    width: 49px;
    height: 19px;

    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */

    /* dark */

    color: #0f2d49;

    margin-left: 0.2rem;
  }
}

.signup__container {
  width: 50%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  padding: 20px;

  @media screen and (max-width: 1000px) {
    width: 70%;
  }

  @media screen and (max-width: 640px) {
    width: 80%;
  }

  h5 {
    margin-bottom: 0.25rem;
    font-size: 0.8rem;
  }

  input {
    height: 2.5rem;
    margin-bottom: 10px;
    background-color: white !important;
    width: 98%;
    padding: 0 0.5rem;
    font-size: 0.8rem;
    border-radius: 0.5rem;
    border: 1px solid #8c9ca7 !important;
  }
  .two-input-view {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3%;

    input {
      height: 2.5rem;
      margin-bottom: 10px;
      background-color: white;
      width: 97%;
      padding: 0 0.5rem;
      font-size: 0.8rem;
      border-radius: 0.5rem;
      border: 1px solid #8c9ca7 !important;
    }

    .LengthData {
      position: absolute;
      font-size: 0.6rem;
      color: #38ab7c;
      margin-top: -0.3rem;
      margin-left: 0.3rem;
    }
    .LengthData_with_Error {
      position: absolute;
      font-size: 0.6rem;
      color: #b50707;
      margin-top: -0.3rem;
      margin-left: 0.3rem;
    }
  }
}
.upload-photo-view {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 3%;
  .BTN_And_Condition {
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
    // height: 4rem;
    input {
      height: 3rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }
  .allowed-pic-details {
    font-family: "roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    // line-height: 68.51%;
    color: #92929d;
    margin-top: 1.5rem !important;
  }

  span {
    font-family: "roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    // line-height: 68.51%;
    /* identical to box height, or 10px */

    color: #ffffff;
  }

  .reset-text {
    color: #92929d;
  }

  .reset-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 12px 24px;
    gap: 8px;
    width: 93px;
    height: 42px;
    margin-left: 1rem;
    background: #ffffff;
    border: 1px solid #92929d;
    border-radius: 4px;
  }

  .upload-pic-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 4px;
    margin: 0 auto;
  }
}
.fileInputButton {
  display: inline-block;
  padding: 8px 15px;
  background-color: #09c865;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 0.5rem;
}

.fileInputButton:hover {
  background-color: #00faab;
}

.fileInputButton:active {
  background-color: #003974;
}
