@media only screen and (max-width: 820px) {
  .MainContainer {
    // border: 1px solid blue;
    display: flex;
    justify-content: center;
    align-items: center;
    .TnCContainer {
      width: 80%;
      // border: 1px solid black;
      height: 100%;
      overflow-y: auto;
      background-color: #fff;
      h1 {
        margin-left: 1rem;
        margin-top: 1.5rem;
        font-size: 1.3rem;
        font-weight: 500;
      }

      li {
        padding: 0.5rem 2rem;
        font-size: 0.9rem;
        font-family: "roboto";
      }
      li:nth-child(2) {
        margin-top: 2rem;
      }
    }
  }
}
//
@media only screen and (max-width: 600px) {
  .MainContainer {
    // border: 1px solid blue;
    display: flex;
    justify-content: center;
    align-items: center;
    .TnCContainer {
      width: 90%;
      // border: 1px solid black;
      height: 100%;
      overflow-y: auto;
      background-color: #fff;
      h1 {
        margin-left: 1rem;
        margin-top: 1rem;
        font-size: 1.3rem;
        font-weight: 500;
      }

      li {
        padding: 0.3rem 2rem;
        font-size: 0.9rem;
        font-family: "roboto";
      }
      li:nth-child(2) {
        margin-top: 1rem;
      }
    }
  }
}
.Action_Button {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin: 1rem 0rem;
  z-index: 999999;
  position: relative;
  right: 12.5%;
  .Update_Button {
    background-color: rgb(56 171 124);
    border-radius: 0.3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 0.8rem;
    color: #fff;
    padding: 0.5rem 1rem;
    margin-right: 1rem;
    z-index: 99999999999;
  }
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input{
    width: 4rem;
    border: 1px solid rgb(56 171 124);
    padding: 0rem 0.2rem;
    margin-right: 1rem;
    outline: none;
    font-size:0.8rem
  }
}

/* TermsAndConditions.scss */
.terms-and-conditions-container {
  margin: 0 auto;
  width: 50%;
  height: 94vh;
  position: relative;
  right: 5%;
  top: 1.3rem;
}

.Bank_BTN {
  display: flex;
  justify-content: center;
  width: fit-content;
  align-items: center;
  background-color: rgb(173, 42, 42);
  padding: 0.2rem 0.5rem;
  border-radius: 0.3rem;
  font-size: 0.8rem;
  cursor: pointer;
  color: #fff;
  z-index: 9999999;
  position: relative;
  left: 0.5rem;
}

.editor-container {
  margin-bottom: 20px;
  overflow-y: auto;
  height: 85vh;
  display: "flex";
  background-color: #fff;
  border-radius: 5px;
  .ql-editor {
    height: 100%;
    p {
      margin: 0.5rem 0;
    }
  }
}

// .ListContainer {
//   display: flex;
//   height: 100%;
//   padding: 0rem 1rem;
//   width: 100%;
//   position: relative;
//   top: -3%;
//   .Previous_TnC {
//     display: flex;
//     align-items: center;
//     flex-direction: column;
//     height: 55%;
//     margin: 5% auto;
//     border: 1.5px solid #aca3a3;
//     padding: 0.5rem 0.2rem;
//     width: 20%;
//     background-color: #fff;
//   }
//   // .Previous_TnC::-webkit-scrollbar {
//   //   display: none;
//   // }
// }
.editor-content {
  border: 1px solid #ccc;
  padding: 10px;
  overflow-y: auto;
  height: 90%;
  background-color: #fff;
  margin-bottom: 2rem;
  border-radius: 5px;
  p {
    padding-bottom: 1rem;
  }
}
.editor-content_Prev {
  border: 1px solid #aca3a3;
  padding: 0.5rem;
  width: 85%;
}

.ql-editor_Prev {
  p {
    font-family: sans-serif;
    text-align: center;
    margin-bottom: 1rem;
  }
}

// Quill font picker styles
.ql-snow .ql-picker.ql-font {
  .ql-picker-label::before,
  .ql-picker-item::before {
    content: attr(data-value) !important;
  }
}
.Save_Button {
  display: flex;
  margin: 0.5rem auto;
}
// Font family definitions
.ql-font-Sans-Serif {
  font-family: "Arial", sans-serif;
}

.ql-font-Serif {
  font-family: "Times New Roman", serif;
}

.ql-font-Monospace {
  font-family: "Courier New", monospace;
}

.ql-font-Arial {
  font-family: "Arial", sans-serif;
}

.ql-font-Times-New-Roman {
  font-family: "Times New Roman", serif;
}

.ql-font-Courier {
  font-family: "Courier New", monospace;
}

// Default font for the editor
.ql-editor {
  font-family: "Arial", sans-serif;
  font-size: 1rem;
}
.ql-editor_Prev {
  font-family: "Arial", sans-serif;
  font-size: 0.6rem;
  margin: 0.5rem 0rem;
}
// Custom styles for the rendered content
.editor-content {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "Arial", sans-serif;
  }

  p {
    font-family: "Arial", sans-serif;
    margin: 0.5rem 0;
  }
}
.editor-content {
  li {
    font-family: "Arial", sans-serif;
  }
}
.editor-content_Prev {
  p,
  li {
    font-family: sans-serif;
    margin-bottom: 0.5rem;
    font-size: 0.5rem;
  }
}
.Version_Container {
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  width: 100%;
  // padding: 0.5rem 0.5rem 0rem 0.5rem;
  margin: 0.1rem 0.5rem;
  z-index:9999999;
  background-color:'#fff';
  cursor: pointer;
  height:15%;
  .UpdatedUser {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0rem 0.2rem 0.2rem 0.3rem;
  }
  .VersionTitle {
    font-family: "Arial", sans-serif;
    font-size: 0.8rem;
    font-weight: 500;
    padding: 0.5rem 0.5rem 0rem 0.3rem;

  }
}
.CurrentVersion {
  color: #222;
  font-size: 0.9rem;
  font-family: sans-serif;
  font-weight: 500;
  z-index: 999999;
  text-align: center;
  margin: 0.2rem 1rem 0rem 0rem;
  border-bottom: 2px solid rgb(56 171 124);
}
// responsvieness
.ListContainer {
  display: flex;
  height: 100%;
  padding: 0rem 1rem;
  width: 100%;
  position: relative;
  top: -3%;

  .Previous_TnC {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 55%;
    margin: 5% auto;
    border: 1.5px solid #aca3a3;
    padding: 0.5rem 0.2rem;
    width: 20%;
    background-color: #fff;
  }

  // Responsive design for smaller screens
  @media only screen and (max-width: 1024px) {
    .Previous_TnC {
      width: 25%; // Slightly wider for medium screens
    }
  }

  @media only screen and (max-width: 768px) {
    .Previous_TnC {
      width: 40%; // Wider for tablets or small devices
    }
  }

  @media only screen and (max-width: 600px) {
    .Previous_TnC {
      width: 50%; // Even wider for mobile devices
    }
  }

  @media only screen and (max-width: 480px) {
    .Previous_TnC {
      width: 70%; // For very small screens
    }
  }

  @media only screen and (max-width: 320px) {
    .Previous_TnC {
      width: 90%; // Nearly full width for the smallest screens
    }
  }
}
