.DialogBox {
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #ffffff;
  border: 1px solid #f2f5fa;
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding: 0.8rem 1rem;

  .DialogItem {
    padding-left: 0.3rem;
    height: 3rem; /* Adjust height as needed */
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      background-color: #d9dcdf;
      border-radius: 8px;
    }

    img {
      width: 24px; /* Adjust icon size */
      height: 24px; /* Adjust icon size */
      margin-right: 10px;
    }

    .ListName {
      font-size: 1rem; /* Increase text size */
      font-family: "Roboto", sans-serif;
      color: #0f2d49;
    }
  }

  .UserId {
    text-align: center;
    margin: 0.5rem auto;
    font-size: 1rem; /* Increase text size */
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    color: #0f2d49;
    border-bottom: 4px solid #38ab7c;
  }
  .UserIdActive {
    text-align: center;
    margin: 0.5rem auto;
    font-size: 1rem; /* Increase text size */
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    color: #0f2d49;
    border-bottom: 4px solid red;
  }
}
