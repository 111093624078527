$primary-color: #007bff;
$dropdown-background: white;
$dropdown-border-color: #ddd;
$dropdown-hover-background: #f1f1f1;
$button-padding: 10px;
$dropdown-padding: 10px;
$dropdown-margin-top: 5px;

.dropdown-content3 {
  position: fixed;
  // top: 18%;
  // right: 17%;
  background-color: $dropdown-background;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 999999999;
  margin-top: $dropdown-margin-top;
  padding: $dropdown-padding;
  border: 1px solid $dropdown-border-color;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      padding: 8px 12px;
      cursor: pointer;
      font-weight: 400;
      font-size: 0.8rem;
      font-family: "Roboto";

      &:hover {
        background-color: $dropdown-hover-background;
      }
    }
  }
}

@media (max-width: 768px) {
  .dropdown-content3 {
    width: 100%;
    left: 0;
    right: 0;
    top: 10%;
    ul {
      li {
        padding: 12px;
        font-size: 1rem;
      }
    }
  }
}

@media (max-width: 480px) {
  .dropdown-content3 {
    top: 5%;
    ul {
      li {
        padding: 16px;
        font-size: 1.2rem;
      }
    }
  }
}

.containerForSearchByProject {
  height: calc(100% - 140px);
  width: 100%;
  padding: 10px 15px;
  overflow: hidden;
  // user-select: none;
}

.NoAdminDataForSearchBusAccount {
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  img {
    height: 50%;
  }
  .NoAdminDataForSearchBusAccount_Title {
    padding: 0.5rem 0;
    text-align: center;

    margin-top: 2rem;
    margin-left: 1.5rem;
    h1 {
      font-size: 2rem;
      color: rgb(103, 93, 93);
    }
    h3 {
      text-align: center;
      font-size: 1.2rem;
      padding: 0.8rem 0;
    }
  }
}

.DataContainer {
  width: 100%;
  height: 76vh;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  margin-top: 2rem;
  .HeaderDetailSearchByProject p {
    font-size: 0.82rem;
    font-weight: 500;
    font-family: "Roboto";
  }
}
.HeadingAndSearchSearchByProject {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0.8rem 0;
  h1 {
    color: #26405e;
    font-weight: 600;
    font-family: "Roboto";
    line-height: 22px;
    font-size: 20px;
    margin-top: 5px;
  }
}
.RightBarSearchByProject {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.2rem;
  p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    text-transform: capitalize;
    color: #26405e;
  }
}
.filters {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 0.5rem;
}
.locationSearchByProject {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #129160;
  border-radius: 5px;
  padding: 4px 8px;
  color: white;
  font-size: 12px;
  border: none;
  outline: none;
}
.filterSearchByProject {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 5px;
  padding: 4px 10px;
  color: black;
  font-size: 13px;
  border: none;
  outline: none;
}
.SearchSearchByProject {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #42426e;
  border-radius: 5px;
  padding: 4px 8px;
  color: white;
  font-size: 12px;
  border: none;
  outline: none;
}
.inputDivSearchByProject {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  // width: 12rem;
  height: 1.9rem;
  margin-right: 0.5rem;
  border-bottom: 1px solid black;
  input {
    outline: none;
    width: 100%;
    height: 100%;
    margin-left: 5px;
    font-size: 12px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    background: none;
    padding-left: 5px;
  }
}

// Headers ---------------
.HeaderDetailSearchByProject {
  // border: 1px solid red;
  height: 80px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 2rem;
}
.userDIvSearchByProject {
  width: 135px;
  // border: 1px solid blue;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.userDIvSearchByProject p {
  margin-left: 1.2rem;
  font-family: "Roboto";
}

.CategorySearchByProject {
  width: 77px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // border: 1px solid blue;
  //  position: relative;
  // left: 1.5rem;
}

.SubSearchByProject {
  width: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // border: 1px solid yellow;
  position: relative;
  right: 1.5rem;
}
.LocationSearchByProject {
  width: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // border: 1px solid yellow;
  position: relative;
  right: 1.5rem;
}
.EmailDivSearchByProject {
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  // border: 1px solid green;
  position: relative;
  left: 2rem;
}
.InvoiceSearchByProject {
  width: 130px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  // border: 1px solid green;
  position: relative;
  left: 2rem;
}
.PaySearchByProject {
  width: 80px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  // border: 1px solid green;
  position: relative;
}
.MessageSearchByProject {
  width: 120px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  // border: 1px solid green;
  position: relative;
  left: 1rem;
}
.ServiceSearchByProject {
  width: 130px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  // border: 1px solid green;
  position: relative;
}
.table-container {
  border: 1px solid red;
}
.Container {
  width: fit-content;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0.5rem;
  gap: 0.5rem;
}
.Header_Common {
  display: flex;
  gap: 0.3rem;
  align-items: center;
  width: fit-content;
  p {
    font-size: 0.8rem;
    font-family: "roboto";
  }
}