.MainContainer {
  // border: 1px solid blue;
  height: calc(100% - 70px);
  padding: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  .TnCContainer {
    width: 100%;
    // border: 1px solid black;
    height: 100%;
    overflow-y: auto;
    background-color: #fff;
    h1 {
      margin-left: 1rem;
      margin-top: 1.5rem;
      font-size: 1.3rem;
      font-weight: 500;
    }

    li {
      padding: 0.5rem 2rem;
      font-size: 0.9rem;
      font-family: "roboto";
    }
    li:nth-child(2) {
      margin-top: 2rem;
    }
  }
}
@media only screen and (max-width: 820px) {
  .MainContainer {
    // border: 1px solid blue;
    display: flex;
    justify-content: center;
    align-items: center;
    .TnCContainer {
      width: 80%;
      // border: 1px solid black;
      height: 100%;
      overflow-y: auto;
      background-color: #fff;
      h1 {
        margin-left: 1rem;
        margin-top: 1.5rem;
        font-size: 1.3rem;
        font-weight: 500;
      }

      li {
        padding: 0.5rem 2rem;
        font-size: 0.9rem;
        font-family: "roboto";
      }
      li:nth-child(2) {
        margin-top: 2rem;
      }
    }
  }
}
//
@media only screen and (max-width: 600px) {
  .MainContainer {
    // border: 1px solid blue;
    display: flex;
    justify-content: center;
    align-items: center;
    .TnCContainer {
      width: 90%;
      // border: 1px solid black;
      height: 100%;
      overflow-y: auto;
      background-color: #fff;
    }
  }
}
